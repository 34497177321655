import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, Grid, Tooltip, styled } from '@mui/material';
import { FileUpload, Delete, Visibility } from '@mui/icons-material';
import { handleError } from '../../../helpers/ErrorHandling';
import { useState } from 'react';
import { useUser } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { deletePatientRecord, getPatientRecordList } from '../../../services/PatientRecordService';
import ViewEditPatientRecordDialog from './ViewEditPatientRecordDialog';
import CreatePatientRecordDialog from './CreatePatientRecordDialog';
import ConfirmationDialog from '../../../helpers/ConfirmationDialog';
import { showSuccessToast } from '../../../helpers/ToastHelper';

const VisibilityIconWrapper = styled(Visibility)`
  cursor: pointer;
  color: grey;
`;

const DeleteIconWrapper = styled(Delete)`
  cursor: pointer;
  color: red;
`;

const PatientRecordMainPage = ({ patient }) => {

    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const { user, clearUser } = useUser();
    const token = user?.access_token;
    const [selectedPatientRecord, setSelectedPatientRecord] = useState(null);
    const [viewEditDialogOpen, setViewEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [allowedRegisterPatientRecord, setAllowedRegisterPatientRecord] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await getPatientRecordList(token, patient.id);
            const userList = response.data || [];
            setRows(userList);
        } catch (error) {
            handleError(error, clearUser, navigate);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();

        if (user.user_type_name !== 'DENTIST') {
            setAllowedRegisterPatientRecord(true);
        }

    }, []);

    const formatDateTime = (dateTimeString) => {
        const [date, time] = dateTimeString.split(' ');
        const [year, month, day] = date.split('/');
        return `${day}/${month}/${year} ${time}`;
    };

    const handleViewPatientRecordOpen = (row) => {
        setSelectedPatientRecord({
            id: row.patient_record_external_id,
            pageNumber: row.patient_record_page_number
        });
        setViewEditDialogOpen(true);
    };

    const handleEditEventDialogClose = () => {
        setSelectedPatientRecord(null);
        setViewEditDialogOpen(false);
        fetchData();
    };

    const handleDeletePatientRecordOpen = (row) => {
        setSelectedPatientRecord({
            id: row.patient_record_external_id,
            pageNumber: row.patient_record_page_number
        });
        setDeleteDialogOpen(true);
    };

    const handleDeleteEventDialogClose = () => {
        setSelectedPatientRecord(null);
        setDeleteDialogOpen(false);
        fetchData();
    };

    const handleCreatePatientRecord = () => {
        setCreateDialogOpen(true);
    };

    const handleCreateEventDialogClose = () => {
        setCreateDialogOpen(false);
        fetchData();
    };

    const handleDeleteDialog = async () => {
        try {
            await deletePatientRecord(user.access_token, selectedPatientRecord.id);
            showSuccessToast(`Ficha do paciente página N.º ${selectedPatientRecord.pageNumber} excluida com sucesso.`, 4000);
            handleDeleteEventDialogClose();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    return (
        <div>
            {loading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '60vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <Grid container spacing={3} alignItems="center">
                    {allowedRegisterPatientRecord && (
                        <Grid item>
                            <Button
                                align="right"
                                variant="contained"
                                style={{ backgroundColor: 'darkgreen', color: 'white', textTransform: 'none' }}
                                onClick={handleCreatePatientRecord}
                                startIcon={<FileUpload />}
                            >
                                Upload nova página
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 780 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <Tooltip title="Número da página da ficha do paciente">
                                            <TableCell align="center"><strong>N.º página</strong></TableCell>
                                        </Tooltip>
                                        <Tooltip title="Data de criação da ficha do paciente">
                                            <TableCell align="center"><strong>Data / Hora</strong></TableCell>
                                        </Tooltip>
                                        <Tooltip title="Médicos dentista responsáveis pelo tratamento do mesmo">
                                            <TableCell align="center"><strong>Médicos dentista</strong></TableCell>
                                        </Tooltip>
                                        <TableCell align="center"><strong>Ações</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">Ainda não há fichas registradas para o paciente.</TableCell>
                                        </TableRow>
                                    ) : (
                                        rows.map((row) => (
                                            <TableRow key={row.patient_record_external_id} sx={{ '& td': { padding: '6px' } }}>
                                                <TableCell align="center">{row.patient_record_page_number}</TableCell>
                                                <TableCell align="center">{formatDateTime(row.created_at)}</TableCell>
                                                <TableCell align="center">{
                                                    row.dentist_summary_list.length > 5 ? (
                                                        <Tooltip
                                                            title={row.dentist_summary_list.map((dentist) => (
                                                                <div key={dentist.user_external_id}>{dentist.dentist_name}</div>
                                                            ))}
                                                        >
                                                            <span>
                                                                {row.dentist_summary_list.slice(0, 5).map((dentist, index) => (
                                                                    <span key={dentist.user_external_id}>
                                                                        {dentist.dentist_name}
                                                                        {index < 4 ? ', ' : ''}
                                                                    </span>
                                                                ))}
                                                                {' ...'}
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        row.dentist_summary_list.map((dentist, index) => (
                                                            <span key={dentist.user_external_id}>
                                                                {dentist.dentist_name}
                                                                {index < row.dentist_summary_list.length - 1 ? ', ' : ''}
                                                            </span>
                                                        ))
                                                    )
                                                }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Visualizar ficha">
                                                        <VisibilityIconWrapper onClick={() => handleViewPatientRecordOpen(row)} />
                                                    </Tooltip>
                                                    {allowedRegisterPatientRecord && (
                                                        <Tooltip title="Remover ficha">
                                                            <DeleteIconWrapper onClick={() => handleDeletePatientRecordOpen(row)} />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
            {selectedPatientRecord && (
                <ViewEditPatientRecordDialog
                    open={viewEditDialogOpen}
                    handleClose={handleEditEventDialogClose}
                    patientRecord={selectedPatientRecord}
                />
            )}
            <CreatePatientRecordDialog
                open={createDialogOpen}
                handleClose={handleCreateEventDialogClose}
                patientId={patient.id}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={handleDeleteEventDialogClose}
                onConfirm={handleDeleteDialog}
                title={"Remover ficha do paciente"}
                contentText={(<>Tem certeza de que deseja excluir a página <strong>N.º {selectedPatientRecord?.pageNumber}?</strong></>)}
            />
        </div>
    );
};

export default PatientRecordMainPage;
