const updatePassword = {
    old_password: 'string',
    new_password: 'string',
    confirm_new_password: 'string'
};

export const mapFormValuesToUpdatePassword = (formValues) => {
    return {
        old_password: formValues.oldPassword,
        new_password: formValues.newPassword,
        confirm_new_password: formValues.confirmNewPassword
    }
};

export default updatePassword;