export const temporaryPassword = '12345678DENTAL';


export const formattedDateToISOString = (formattedDate) => {
    const dateParts = formattedDate.split(' ');
    const [date, time] = dateParts;

    const [year, month, day] = date.split('/');
    const [hours, minutes] = time.split(':');

    const dateObject = new Date(year, month - 1, day, hours, minutes);

    const isoString = dateObject.toISOString();

    return isoString;
};