const quickPatientRegistration = {
    name: 'string',
    email: 'string',
    phoneNumber: 'string'
};

export const mapFormValuesToQuickPatientRegistration = (formValues, healthInsuranceId, birhtdate) => {
    return {
        name: formValues.name,
        birthdate: birhtdate,
        phone_number: formValues.phoneNumber,
        taxpayer_number: formValues.taxpayerNumber,
        email: formValues.email,
        health_insurance_id: healthInsuranceId
    };
};

export default quickPatientRegistration;