import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CircularProgress, Tooltip, styled } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt';
import DentalDashboard from '../dashboard/DentalDashboard';
import { getAppointments } from '../../services/ScheduleService';
import { handleError } from '../../helpers/ErrorHandling';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import CalendarFilters from './CalendarFilters';
import CreateEventDialog from './CreateEventDialog';
import ViewEventDialog from './ViewEventDialog';
import { Cancel, Check, Warning, EventRepeat } from '@mui/icons-material';
import { mapAppointmentStatusToText } from '../../helpers/EnumHelper';

moment.locale('pt');

const localizer = momentLocalizer(moment);

const StyledCalendar = styled(Calendar)`
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  .rbc-month-view {
    background-color: #fff;
  }

  .rbc-event {
    color: white;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-today {
    background-color: #C9ECD2;
  }
`;

const messages = {
    today: 'Hoje',
    previous: 'Anterior',
    next: 'Próximo',
    month: 'Mês',
    week: 'Semana',
    work_week: 'Semana útil',
    day: 'Dia',
    date: 'Data',
    time: 'Horário',
    event: 'Consultas',
    year: 'Ano'
};

const monthNameMap = {
    'JANEIRO': 'JANUARY',
    'FEVEREIRO': 'FEBRUARY',
    'MARÇO': 'MARCH',
    'ABRIL': 'APRIL',
    'MAIO': 'MAY',
    'JUNHO': 'JUNE',
    'JULHO': 'JULY',
    'AGOSTO': 'AUGUST',
    'SETEMBRO': 'SEPTEMBER',
    'OUTUBRO': 'OCTOBER',
    'NOVEMBRO': 'NOVEMBER',
    'DEZEMBRO': 'DECEMBER'
};

const getStatusIcon = (status) => {
    switch (status) {
        case 'CONFIRMED':
            return <Check style={{ minWidth: 'auto', marginRight: '2px', fontSize: '16px' }} />;
        case 'NOT_CONFIRMED':
            return <Warning style={{ minWidth: 'auto', marginRight: '2px', fontSize: '16px' }} />;
        case 'CANCELED_RESCHEDULED':
            return <EventRepeat style={{ minWidth: 'auto', marginRight: '2px', fontSize: '16px' }} />;              
        case 'CANCELED_NOT_RESCHEDULED':
            return <Cancel style={{ minWidth: 'auto', marginRight: '2px', fontSize: '16px' }} />;          
        default:
            return null;
    }
};

const CalendarPage = () => {
    const navigate = useNavigate();
    const { user, clearUser } = useUser();
    const [appointments, setAppointments] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [selectedDentists, setSelectedDentists] = useState([]);
    const [selectedEventStatuses, setSelectedEventStatuses] = useState([]);
    const [loadingAppointments, setLoadingAppointments] = useState(false);
    const [transformedEvents, setTransformedEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);
    const [openCreateEventDialog, setOpenCreateEventDialog] = useState(false);
    const [openViewEventDialog, setOpenUpdateEventDialog] = useState(false);
    
    const minTime = new Date().setHours(6, 0, 0);
    const maxTime = new Date().setHours(22, 0, 0);
    const [selectedMonth, setSelectedMonth] = useState(moment().format('MMMM').toLocaleUpperCase());
    const [selectedYear, setSelectedYear] = useState(moment().year());

    const handleCreateEventDialog = (event) => {
        if (user.user_type_name !== 'DENTIST') {
            setSelectedDate(event.start);
            setSelectedStartTime(event.start);
            setSelectedEndTime(event.end);
            setOpenCreateEventDialog(true);
        }
    };

    const handleViewUpdateEventDialog = (event) => {
        setSelectedAppointment(event);
        setOpenUpdateEventDialog(true);
    };

    const handleCreateEventDialogClose = () => {
        setOpenCreateEventDialog(false);
    };

    const handleViewEventDialogClose = () => {
        setSelectedAppointment(null);
        setOpenUpdateEventDialog(false);
    };

    const handleFormSubmit = (month, year) => {
        fetchAppointmentsData(month, year);
        setOpenCreateEventDialog(false);
    };

    const handleDialogClose = () => {
        fetchAppointmentsData(selectedMonth, selectedYear);
    };

    const fetchAppointmentsData = async (month, year) => {
        try {
            setLoadingAppointments(true);
            const englishMonth = monthNameMap[month];
            const response = await getAppointments(user.access_token, englishMonth, year);

            const filteredAppointments = response.data.filter(appointment =>
                selectedDentists.includes(appointment.dentist_summary.user_external_id) &&
                selectedEventStatuses.includes(appointment.appointment_status)
            );

            setAppointments(filteredAppointments);
        } catch (error) {
            handleError(error, clearUser, navigate);
        } finally {
            setLoadingAppointments(false);
        }
    };

    useEffect(() => {
        fetchAppointmentsData(selectedMonth, selectedYear, null);
    }, [selectedDentists, selectedEventStatuses, selectedMonth, selectedYear]);

    useEffect(() => {
        const transformedEvents = transformEventData(appointments);
        setTransformedEvents(transformedEvents);
    }, [appointments]);

    const transformEventData = (appointments) => {
        return appointments.map(appointment => {

            const startDateTime = moment(appointment.start_event_date_time);
            const startTime = startDateTime.format('HH:mm');

            const title = appointment.patient_summary.is_enabled
                ? (
                    <div>
                        <span style={{ fontSize: '15px' }}> {startTime} - </span>
                        <span>{appointment.patient_summary.patient_abbreviate_name}</span>
                    </div>
                )
                : (
                    <div>
                        <span style={{ fontSize: '15px' }}> {startTime}</span>
                        <span>[P. removido] {appointment.patient_summary.patient_abbreviate_name}</span>
                    </div>
                );

            return {
                id: appointment.appointment_external_id,
                title: title,
                appointment_status: appointment.appointment_status,
                start: new Date(appointment.start_event_date_time),
                end: new Date(appointment.end_event_date_time),
                backgroundColor: appointment.dentist_summary.primary_color,
                dentistName: appointment.dentist_summary.dentist_name,
                patientName: appointment.patient_summary.patient_name
            };
        });
    };

    const eventStyleGetter = (event) => {
        const backgroundColor = event.backgroundColor;

        return {
            style: {
                backgroundColor,
                fontSize: '15px', // Increase font size
                padding: '5px', // Add padding to events
                borderRadius: '5px', // Add rounded corners to events
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add shadow for depth
                cursor: 'pointer', // Change cursor to indicate clickability
                display: 'flex', // Use flexbox to align items vertically
                alignItems: 'center', // Align items vertically
                justifyContent: 'center', // Center items horizontally
                height: '100%' // Ensure the event item takes the full height
            },
        };
    };

    const handleNavigate = (newDate) => {
        const newMonth = moment(newDate).format('MMMM').toUpperCase();
        const newYear = newDate.getFullYear();
        setSelectedMonth(newMonth);
        setSelectedYear(newYear);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', marginLeft: '300px' }}>
            {loadingAppointments && <CircularProgress color="success" style={{ position: 'absolute', zIndex: 1 }} />}

            <DentalDashboard />

            <div style={{ overflowY: 'auto', maxHeight: '80vh', marginRight: '20px' }}>
                <CalendarFilters
                    setSelectedDentists={setSelectedDentists}
                    selectedDentists={selectedDentists}
                    selectedEventStatuses={selectedEventStatuses}
                    setSelectedEventStatuses={setSelectedEventStatuses}
                    onDialogClose={handleDialogClose}
                />
            </div>


            <StyledCalendar
                localizer={localizer}
                events={transformedEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '740px', width: '90%' }}
                views={{ month: true, week: true, work_week: true, day: true, agenda: false }}
                selectable
                onSelectSlot={handleCreateEventDialog}
                eventPropGetter={eventStyleGetter}
                onNavigate={handleNavigate}
                messages={messages}
                onSelectEvent={handleViewUpdateEventDialog}
                popup
                min={minTime}
                max={maxTime}
                dayLayoutAlgorithm={'no-overlap'}
                tooltipAccessor={() => null}
                components={{
                    event: ({ event }) => (
                        <div title="">
                            <Tooltip title={`${mapAppointmentStatusToText(event.appointment_status)} - Dentista: ${event.dentistName}`}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {getStatusIcon(event.appointment_status)}
                                    <span style={{ marginLeft: '5px' }}>{event.title}</span>
                                </div>
                            </Tooltip>
                        </div>
                    )
                }}
            />

            <CreateEventDialog
                open={openCreateEventDialog}
                handleClose={handleCreateEventDialogClose}
                chosenDateFromCalendar={selectedDate}
                chosenStartTimeFromCalendar={selectedStartTime}
                chosenEndTimeFromCalendar={selectedEndTime}
                handleFormSubmit={(month, year) => handleFormSubmit(month, year)}
            />
            {selectedAppointment && (
                <ViewEventDialog
                    open={openViewEventDialog}
                    handleClose={handleViewEventDialogClose}
                    selectedAppointment={selectedAppointment}
                    handleFormSubmit={(month, year) => handleFormSubmit(month, year)}
                />
            )}
        </div>
    );
};

export default CalendarPage;