import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { FormErrorV2 } from '../../helpers/FormError';
import { patientsValidationSchema } from '../../helpers/ValidationSchemaHelper';
import { Delete, People } from '@mui/icons-material';
import { useUser } from '../../contexts/UserContext';
import { createPatient, getPatientDetails } from '../../services/PatientService';
import { showErrorToast, showSuccessToast } from '../../helpers/ToastHelper';
import { mapFormValuesToQuickPatientRegistration } from '../../data/quickPatientRegistration';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../helpers/ErrorHandling';
import { createHealthInsurance, deleteHealthInsurance, getHealthInsuranceList } from '../../services/HealthInsuranceService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const CreatePatientDialog = ({ open, handleClose, onPatientCreated }) => {
    const { user, clearUser } = useUser();
    const navigate = useNavigate();
    const [initialized, setInitialized] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [healthInsuranceDropdownOptions, setHealthInsuranceDropdownOptions] = useState([]);
    const [selectedHealthInsurance, setSelectedHealthInsurance] = useState({ label: 'Não informado', value: null });
    const [isAddingNewInsurance, setIsAddingNewInsurance] = useState(false);

    useEffect(() => {
        if (open && !initialized) {
            fetchHealthInsuranceDropdownOptions();
            setInitialized(true);
        }
    }, [open, initialized]);

    const handleChange = (date) => {
        setSelectedDate(date);
    };

    const fetchHealthInsuranceDropdownOptions = async () => {
        try {
            const response = await getHealthInsuranceList(user.access_token);
            const options = response.data.map((healthInsurance) => ({
                value: healthInsurance.health_insurance_id,
                label: healthInsurance.name,
                isDefault: healthInsurance.is_default
            }));

            setHealthInsuranceDropdownOptions(options);

            if (options.length > 0) {
                setSelectedHealthInsurance(options[0]);
            }
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleCreatePatient = async (values) => {

        if (!Object.values(values).every(value => value.trim() !== '') || selectedDate === null) {
            showErrorToast("Todos os dados são obrigatórios", 5000);
            return;
        }

        const formattedDate = dayjs(selectedDate).locale('pt').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        try {
            await patientsValidationSchema.validate(values, { abortEarly: false });
            const mappedPatient = mapFormValuesToQuickPatientRegistration(values, selectedHealthInsurance.value, formattedDate);
            var apiResponse = await createPatient(user.access_token, mappedPatient);

            onPatientCreated(mappedPatient, apiResponse.data.patient_external_id);

            showSuccessToast(`Paciente ${mappedPatient.name} criado com sucesso.`, 3000);
            handleClose();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleHealthInsuranceChange = (event) => {

        const selectedHealthInsuranceId = event.target.value;

        if (selectedHealthInsuranceId === 'add-new') {
            setIsAddingNewInsurance(true);
            setSelectedHealthInsurance('');
        } else {
            const selectedOption = healthInsuranceDropdownOptions.find(option => option.value === selectedHealthInsuranceId);
            setSelectedHealthInsurance(selectedOption || { label: 'Não selecionado', value: null });
            setIsAddingNewInsurance(false);
        }
    };

    const handleDeleteHealthInsurance = async (id) => {
        try {
            await deleteHealthInsurance(user.access_token, id);
            showSuccessToast('Convenção/Seguro deletada com sucesso.');
            fetchHealthInsuranceDropdownOptions();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleAddNewHealthInsurance = async (event) => {
        if (event.key === 'Enter' && event.target.value.trim()) {
            try {
                const newHealthInsuranceName = event.target.value;
                const response = await createHealthInsurance(user.access_token, { name: newHealthInsuranceName });
                const newOption = { value: response.data.health_insurance_id, label: response.data.name, isDefault: response.is_default };
                setHealthInsuranceDropdownOptions([...healthInsuranceDropdownOptions, newOption]);
                setSelectedHealthInsurance(newOption);
                setIsAddingNewInsurance(false);
                showSuccessToast(`Convenção/Seguro "${newHealthInsuranceName}" adicionado com sucesso.`);
            } catch (error) {
                handleError(error, clearUser, navigate);
            }
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <Formik
                    initialValues={
                        {
                            name: '',
                            taxpayerNumber: '',
                            phoneNumber: '',
                            email: ''
                        }
                    }
                    enableReinitialize
                    onSubmit={handleCreatePatient}
                >
                    {() => (
                        <Form id="patientForm" className="default-form">
                            <Grid item xs={12} sm={9}>
                                <Typography variant="personal-data" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                                    <IconButton color="inherit" aria-label="menu">
                                        <People />
                                    </IconButton>
                                    Registrar novo paciente - Dados básicos
                                </Typography>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={10}>
                                        <Field
                                            type="text"
                                            name="name"
                                            label="* Nome"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            helperText={<FormErrorV2 name="name" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={10}>
                                        <FormControl style={{ width: '100%' }} margin="normal">
                                            <LocalizationProvider
                                                name='birthdate'
                                                dateAdapter={AdapterDayjs}
                                                adapterLocale="pt"
                                                style={{ width: '100%' }}
                                                margin="normal"
                                            >
                                                <DatePicker
                                                    name='birthdate'
                                                    label="Data nascimento"
                                                    format="DD/MM/YYYY"
                                                    onChange={handleChange}
                                                />
                                                {/* //{birthdateError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{birthdateError}</div>} */}
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={10}>
                                        <Field
                                            type="text"
                                            name="phoneNumber"
                                            label="* Telemóvel"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            helperText={<FormErrorV2 name="phoneNumber" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={10}>
                                        <Field
                                            type="text"
                                            name="email"
                                            label="* E-mail"
                                            placeholder="joana.almeida@gmail.com"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            helperText={<FormErrorV2 name="email" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={10}>
                                        <Field
                                            type="text"
                                            name="taxpayerNumber"
                                            label="* Contribuinte (NIF)"
                                            placeholder="123456789"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            helperText={<FormErrorV2 name="taxpayerNumber" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={10}>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel id="select-health-insurance">Convenção/Seguro</InputLabel>
                                            <Field
                                                as={Select}
                                                name="medicalInsurance"
                                                labelId="health-insurance-label"
                                                value={selectedHealthInsurance.value || ''}
                                                onChange={handleHealthInsuranceChange}
                                                label="Convenção/Seguro"
                                                displayEmpty
                                            >
                                                {healthInsuranceDropdownOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                        {!option.isDefault && (
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={() => handleDeleteHealthInsurance(option.value)}
                                                                size="small"
                                                                sx={{ marginLeft: 'auto' }}
                                                            >
                                                                <Delete fontSize="small" />
                                                            </IconButton>
                                                        )}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem value="add-new" sx={{ fontStyle: 'italic', color: 'green' }}>
                                                    + Criar novo
                                                </MenuItem>
                                            </Field>
                                            {isAddingNewInsurance && (
                                                <TextField
                                                    placeholder="Digite o novo nome"
                                                    fullWidth
                                                    margin="normal"
                                                    onKeyDown={handleAddNewHealthInsurance}
                                                    autoFocus
                                                />
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>


                            </Grid>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                    Cancelar
                                </Button>
                                <Button type="submit" color="primary">
                                    Criar Paciente
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default CreatePatientDialog;
