import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/audit-service/api/v1`

const api = axios.create({
    baseURL: API_BASE_URL
});

export const getAutocompleteSearchAudits = async (token, pageNumber, pageSize) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.get(`/audit/autocomplete?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
