import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Formik, Form, Field } from 'formik';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FormErrorV2 } from '../../helpers/FormError';
import { createPatient } from '../../services/PatientService';
import { useUser } from '../../contexts/UserContext';
import { showSuccessToast } from '../../helpers/ToastHelper';
import { patientsValidationSchema } from '../../helpers/ValidationSchemaHelper';
import { mapFormValuesToPatientDetails } from '../../data/updatePatientDetails';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../helpers/ErrorHandling';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { createHealthInsurance, deleteHealthInsurance, getHealthInsuranceList } from '../../services/HealthInsuranceService';
import { Delete } from '@mui/icons-material';

const AddPatientDialog = ({ open, onClose }) => {
    const { user, clearUser } = useUser();
    const [initialized, setInitialized] = useState(false);
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(null);
    const [birthdateError, setBirthdateError] = useState('');
    const [healthInsuranceDropdownOptions, setHealthInsuranceDropdownOptions] = useState([]);
    const [selectedHealthInsurance, setSelectedHealthInsurance] = useState({ label: 'Não informado', value: null });
    const [isAddingNewInsurance, setIsAddingNewInsurance] = useState(false);

    useEffect(() => {
        if (open && !initialized) {
            fetchHealthInsuranceDropdownOptions();
            setInitialized(true);
        }
    }, [open, initialized]);

    const handleCreatePatient = async (values) => {

        try {

            var formError = false;
            var formattedDate;

            if (selectedDate) {
                setBirthdateError('');
                formattedDate = dayjs(selectedDate).locale('pt').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            } else {
                setBirthdateError('Data nascimento é obrigatório');
                formError = true;
            }

            if (formError) return;

            await patientsValidationSchema.validate(values, { abortEarly: false });

            const mappedPatient = mapFormValuesToPatientDetails(values, formattedDate, selectedHealthInsurance.value);
            await createPatient(user.access_token, mappedPatient);

            showSuccessToast(`Paciente ${mappedPatient.name} criado com sucesso.`, 3000);
            onCloseDialog();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const fetchHealthInsuranceDropdownOptions = async () => {
        try {
            const response = await getHealthInsuranceList(user.access_token);
            const options = response.data.map((healthInsurance) => ({
                value: healthInsurance.health_insurance_id,
                label: healthInsurance.name,
                isDefault: healthInsurance.is_default
            }));

            setHealthInsuranceDropdownOptions(options);

            if (options.length > 0) {
                setSelectedHealthInsurance(options[0]);
            }
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleChange = (date) => {
        setSelectedDate(date);
    };

    const onCloseDialog = () => {
        setBirthdateError('')
        setSelectedHealthInsurance(healthInsuranceDropdownOptions[0])
        setSelectedDate(null)
        onClose()
    };

    const handleHealthInsuranceChange = (event) => {

        const selectedHealthInsuranceId = event.target.value;

        if (selectedHealthInsuranceId === 'add-new') {
            setIsAddingNewInsurance(true);
            setSelectedHealthInsurance('');
        } else {
            const selectedOption = healthInsuranceDropdownOptions.find(option => option.value === selectedHealthInsuranceId);
            setSelectedHealthInsurance(selectedOption || { label: 'Não selecionado', value: null });
            setIsAddingNewInsurance(false);
        }
    };

    const handleAddNewHealthInsurance = async (event) => {
        if (event.key === 'Enter' && event.target.value.trim()) {
            try {
                const newHealthInsuranceName = event.target.value;
                const response = await createHealthInsurance(user.access_token, { name: newHealthInsuranceName });
                const newOption = { value: response.data.health_insurance_id, label: response.data.name, isDefault: response.is_default };
                setHealthInsuranceDropdownOptions([...healthInsuranceDropdownOptions, newOption]);
                setSelectedHealthInsurance(newOption);
                setIsAddingNewInsurance(false);
                showSuccessToast(`Convenção/Seguro "${newHealthInsuranceName}" adicionado com sucesso.`);
            } catch (error) {
                handleError(error, clearUser, navigate);
            }
        }
    };

    const handleDeleteHealthInsurance = async (id) => {
        try {
            await deleteHealthInsurance(user.access_token, id);
            showSuccessToast('Convenção/Seguro deletada com sucesso.');
            fetchHealthInsuranceDropdownOptions();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    return (
        <Dialog open={open} onClose={onCloseDialog} >
            <Formik
                initialValues={
                    {
                        invoicingId: '',
                        name: '',
                        email: '',
                        phoneNumber: '',
                        profession: '',
                        citizenshipCard: '',
                        taxpayerNumber: '',
                        utenteNumber: '',
                        medicalInsurance: '',
                        street: '',
                        locality: '',
                        zipCode: ''
                    }
                }
                validationSchema={patientsValidationSchema}
                onSubmit={handleCreatePatient}
            >
                {() => (
                    <Form id="patientForm" className="default-form">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={9}>
                                <Typography variant="personal-data" gutterBottom align='center' style={{ fontWeight: 'bold', color: 'green' }}>
                                    Cadastrar novo paciente
                                </Typography>
                            </Grid>
                        </Grid>
                        <div style={{ margin: '20px 0' }}></div>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="invoicingId"
                                    label="ID faturamento"
                                    placeholder="1234"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="invoicingId" />}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={7.6}>
                                <Field
                                    type="text"
                                    name="name"
                                    label="* Nome"
                                    placeholder="Joana de Almeida Ferreira"
                                    as={TextField}
                                    vvariant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="name" />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4.4}>
                                <FormControl style={{ width: '100%' }} margin="normal">
                                    <LocalizationProvider
                                        name='birthdate'
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="pt"
                                        style={{ width: '100%' }}
                                        margin="normal"
                                    >
                                        <DatePicker
                                            name='birthdate'
                                            label="* Data nascimento"
                                            format="DD/MM/YYYY"
                                            onChange={handleChange}
                                        />
                                        {birthdateError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{birthdateError}</div>}
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <Field
                                    type="text"
                                    name="phoneNumber"
                                    label="* Telemóvel"
                                    placeholder="912258452"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="phoneNumber" />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <Field
                                    type="text"
                                    name="email"
                                    label="* E-mail"
                                    placeholder="joana.almeida@gmail.com"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="email" />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="profession"
                                    label="Profissão"
                                    placeholder="Atendente telemarketing"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="profession" />}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="citizenshipCard"
                                    label="Cartão cidadão"
                                    placeholder="123456789"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="citizenshipCard" />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="taxpayerNumber"
                                    label="* Contribuinte (NIF)"
                                    placeholder="123456789"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="taxpayerNumber" />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="utenteNumber"
                                    label="Número utente"
                                    placeholder="123456789"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="utenteNumber" />}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="select-health-insurance">Convenção/Seguro</InputLabel>
                                    <Field
                                        as={Select}
                                        name="medicalInsurance"
                                        labelId="health-insurance-label"
                                        value={selectedHealthInsurance.value || ''}
                                        onChange={handleHealthInsuranceChange}
                                        label="Convenção/Seguro"
                                        displayEmpty
                                    >
                                        {healthInsuranceDropdownOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                {!option.isDefault && (
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() => handleDeleteHealthInsurance(option.value)}
                                                        size="small"
                                                        sx={{ marginLeft: 'auto' }}
                                                    >
                                                        <Delete fontSize="small" />
                                                    </IconButton>
                                                )}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value="add-new" sx={{ fontStyle: 'italic', color: 'green' }}>
                                            + Criar novo
                                        </MenuItem>
                                    </Field>
                                    {isAddingNewInsurance && (
                                        <TextField
                                            placeholder="Digite o novo nome"
                                            fullWidth
                                            margin="normal"
                                            onKeyDown={handleAddNewHealthInsurance}
                                            autoFocus
                                        />
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6}>
                                <Field
                                    type="text"
                                    name="locality"
                                    label="Localidade"
                                    placeholder="Outreiros"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="locality" />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="zipCode"
                                    label="Código postal"
                                    placeholder="01458795"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    helperText={<FormErrorV2 name="zipCode" />}
                                />
                            </Grid>

                        </Grid>
                        <div style={{ margin: '20px 0' }}></div>
                        <DialogActions sx={{ justifyContent: 'flex-start' }}>
                            <Button onClick={onCloseDialog}
                                variant="contained"
                                style={{ backgroundColor: 'white', color: 'black' }}
                            >
                                Cancelar
                            </Button>
                            <div style={{ marginLeft: '20px' }}></div>
                            <Button type="submit"
                                variant="contained"
                                style={{ backgroundColor: 'darkgreen', color: 'white' }}
                            >
                                Cadastrar
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default AddPatientDialog;
