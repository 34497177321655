import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import sorrisoSoberanoLogo from '../../../images/sorriso_soberano_logo_01.png';
import {
    CalendarMonth as CalendarMonthIcon,
    Settings as SettingsIcon,
    Logout as LogoutIcon,
    BarChart as BarChartIcon,
    AccountCircle
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/UserContext';
import { showWarnToast } from '../../../helpers/ToastHelper';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { HOMEPAGE_PATH, SCHEDULE_PATH, SETTINGS_PROFILE_PATH, PERFORMANCE_ANALYSIS_PATH } from '../../../Constants';


const drawerWidth = 240;

const settings = ['Perfil e configurações', 'Logout'];

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const Sidebar = ({ items, title }) => {
    const navigate = useNavigate();
    const { clearUser } = useUser();
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const initialItems = [
        {
            text: 'Calendário',
            icon: <CalendarMonthIcon />,
            onClick: () => navigate(SCHEDULE_PATH),
        },
        {
            text: 'Análise e desempenho',
            icon: <BarChartIcon />,
            onClick: () => navigate(PERFORMANCE_ANALYSIS_PATH),
        }
    ];

    const lastItems = [
        {
            text: 'Perfil e configurações',
            icon: <SettingsIcon />,
            onClick: () => navigate(SETTINGS_PROFILE_PATH),
        },
        {
            text: 'Logout',
            icon: <LogoutIcon />,
            onClick: () => {
                clearUser();
                navigate(HOMEPAGE_PATH);
                showWarnToast('Logout realizado com sucesso');
            },
        },
    ];

    const handleProfileClick = () => {
        navigate(SETTINGS_PROFILE_PATH);
    };

    const handleLogoutClick = () => {
        clearUser();
        navigate(HOMEPAGE_PATH);
        showWarnToast('Logout realizado com sucesso');
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const combinedItems = [...initialItems, ...items];

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ backgroundColor: 'darkgreen', zIndex: theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={sorrisoSoberanoLogo} alt="App Logo" className="logo-sidebar" />
                    <Typography variant="h6" noWrap component="div">
                        {title}
                    </Typography>

                    {/* Move the Avatar to the right side */}
                    <Box sx={{ flexGrow: 1 }} />

                    <Tooltip title="Abrir opções" placement="top">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mt: 0 }}>
                            <AccountCircle sx={{ color: 'white', fontSize: '40px' }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={setting === 'Perfil e configurações' ? handleProfileClick : handleLogoutClick}>
                                <Typography textAlign="center">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Toolbar>
            </AppBar>

            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {combinedItems.map((item) => (
                        <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={item.onClick}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                }}
                            >
                                <Tooltip title={item.text}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 'auto' : '0', justifyContent: 'center', color: 'darkgreen' }}>
                                        {item.icon}
                                    </ListItemIcon>
                                </Tooltip>
                                {open && (
                                    <ListItemText primary={item.text} sx={{ opacity: 1, ml: open ? 2 : 0 }} />
                                )}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    {lastItems.map((item) => (
                        <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={item.onClick}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center', // Center the content
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 'auto' : '0', justifyContent: 'center', color: 'gray' }}>
                                    {item.icon}
                                </ListItemIcon>
                                {open && (
                                    <ListItemText primary={item.text} sx={{ opacity: 1, ml: open ? 2 : 0 }} />
                                )}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
            </Box>
        </Box>
    );
};

export default Sidebar;
