import React from 'react';
import Sidebar from './Sidebar';
import {
  Badge as BadgeIcon,
  Groups2 as GroupIcon,
  Policy as Policy
} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { PATIENT_MANAGEMENT_PATH, USERS_MANAGEMENT_PATH, AUDIT_PATH } from '../../../Constants';

const AdminSidebar = () => {

  const navigate = useNavigate();

  const items = [
    {
      text: 'Utilizadores do sistema',
      icon: <BadgeIcon />,
      onClick: () => navigate(USERS_MANAGEMENT_PATH)
    },
    {
      text: 'Pacientes',
      icon: <GroupIcon />,
      onClick: () => navigate(PATIENT_MANAGEMENT_PATH),
    },
    {
      text: 'Auditoria',
      icon: <Policy />,
      onClick: () => navigate(AUDIT_PATH),
    }
  ];

  const title = 'Sorriso Soberano - Admin Dashboard';

  return <Sidebar items={items} title={title} />;
};

export default AdminSidebar;
