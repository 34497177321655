import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, Grid, IconButton, MenuItem, Select, InputLabel, FormControl, Autocomplete, TextField, ListItemIcon, ListItem, ListItemText, Paper, Tooltip } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import EventIcon from '@mui/icons-material/Event';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/joy/styles';
import { Textarea } from '@mui/joy';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { getUsers } from '../../services/UserService';
import { useUser } from '../../contexts/UserContext';
import { getAutocompleteSearchPatients } from '../../services/PatientService';
import { handleError } from '../../helpers/ErrorHandling';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import WarningIcon from '@mui/icons-material/Warning';
import QuickCreatePatientDialog from './QuickCreatePatientDialog';
import CreateEventConfirmationDialog from './CreateEventConfirmationDialog';
import mapValuesToAppointmentRegistration from '../../data/appointmentRegistration';
import { createAppointment } from '../../services/ScheduleService';
import { showSuccessToast } from '../../helpers/ToastHelper';
import moment from 'moment';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

const initialValues = {
    dentist: '',
    patient: '',
    description: ''
};

const StyledTextarea = styled(TextareaAutosize)({
    resize: 'none',
    border: 'none', // remove the native textarea border
    minWidth: 0, // remove the native textarea width
    outline: 0, // remove the native textarea outline
    padding: 0, // remove the native textarea padding
    paddingBlockStart: '1em',
    paddingInlineEnd: `var(--Textarea-paddingInline)`,
    flex: 'auto',
    alignSelf: 'stretch',
    color: 'inherit',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    '&::placeholder': {
        opacity: 0,
        transition: '0.1s ease-out',
    },
    '&:focus::placeholder': {
        opacity: 1,
    },
    // specific to TextareaAutosize, cannot use '&:focus ~ label'
    '&:focus + textarea + label, &:not(:placeholder-shown) + textarea + label': {
        top: '0.5rem',
        fontSize: '0.75rem',
    },
    '&:focus + textarea + label': {
        color: 'var(--Textarea-focusedHighlight)',
    },
});

const StyledLabel = styled('label')(({ theme }) => ({
    position: 'absolute',
    lineHeight: 1,
    top: 'calc((var(--Textarea-minHeight) - 1em) / 2)',
    color: theme.vars.palette.text.tertiary,
    fontWeight: theme.vars.fontWeight.md,
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

const InnerTextarea = React.forwardRef(function InnerTextarea(props, ref) {
    const id = React.useId();
    return (
        <React.Fragment>
            <StyledTextarea minRows={2} {...props} ref={ref} id={id} />
            <StyledLabel htmlFor={id}>Descrição (opcional)</StyledLabel>
        </React.Fragment>
    );
});

const CreateEventDialog = ({ open, handleClose, chosenDateFromCalendar, chosenStartTimeFromCalendar, chosenEndTimeFromCalendar, handleFormSubmit }) => {
    const [dentistError, setDentistError] = useState('');
    const [patientError, setPatientError] = useState('');
    const [startTimeError, setStartTimeError] = useState('');
    const [endTimeError, setEndTimeError] = useState('');
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [createPatientDialogOpen, setCreatePatientDialogOpen] = useState(false);
    const { user, clearUser } = useUser();
    const [dentistsDropdownOptions, setDentistsDropdownOptions] = useState([]);
    const [selectedDentist, setSelectedDentist] = useState('');
    const [selectedPatient, setSelectedPatient] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('NOT_CONFIRMED');
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const [initialized, setInitialized] = useState(false);
    const [selectedDate, setSelectedDate] = useState(chosenDateFromCalendar);
    const [selectedStartDateTime, setSelectedStartDateTime] = useState(chosenStartTimeFromCalendar);
    const [selectedEndDateTime, setSelectedEndDateTime] = useState(chosenEndTimeFromCalendar);

    const cleanForms = () => {
        setSelectedDentist('');
        setSelectedPatient('');
        setDescription('');
        setDentistError('');
        setPatientError('');
        setSelectedDate('');
        setStartTimeError('');
        setEndTimeError('');
        setStatus('NOT_CONFIRMED');
    }

    const handleCloseDialog = () => {
        cleanForms();
        handleClose();
    };

    useEffect(() => {
        setSelectedDate(chosenDateFromCalendar);
        setSelectedStartDateTime(chosenStartTimeFromCalendar);
        setSelectedEndDateTime(chosenEndTimeFromCalendar);

        if (open && !initialized) {
            fetchDentistsDropdownOptions();
            setInitialized(true);
        }

    }, [open], [chosenDateFromCalendar, chosenStartTimeFromCalendar, chosenEndTimeFromCalendar]);

    const handlePatientCreated = (createdPatient, patientExternalId) => {
        createdPatient.patient_external_id = patientExternalId;
        setSelectedPatient(createdPatient);
    };

    const fetchDentistsDropdownOptions = async () => {
        try {
            const response = await getUsers(user.access_token, 0, 10000, 'DENTIST');
            const options = response.data.content.map((user) => ({
                value: user.user_external_id,
                label: `${user.first_name} ${user.last_name}`,
            }));
            setDentistsDropdownOptions(options);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const fetchSearchResults = async (query) => {
        try {
            const response = await getAutocompleteSearchPatients(user.access_token, query, 0, 10);
            const content = response.data.content || [];
            setSearchResults(content);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleSearchChange = (event, query) => {
        setSearchText(query);
        fetchSearchResults(query);
    };

    const handleDentistChange = (event) => {
        const selectedDentistId = event.target.value;
        let dentist = dentistsDropdownOptions.find(option => option.value === selectedDentistId);
        if (dentist == null) {
            dentist = {
                label: 'Não selecionado',
                value: null
            };
        }
        setSelectedDentist(dentist);
    };

    const openCreatePatientDialog = () => {
        setCreatePatientDialogOpen(true);
    };

    const closeConfirmationDialog = () => {
        setConfirmationDialogOpen(false);
    };

    const handleStartTimeChange = (newValue) => {
        setSelectedStartDateTime(newValue);
    };

    const handleEndTimeChange = (newValue) => {
        setSelectedEndDateTime(newValue);
    };

    const handleConfirmCreation = async () => {
        try {

            const startDateTime = dayjs(selectedDate).hour(dayjs(selectedStartDateTime).hour()).minute(dayjs(selectedStartDateTime).minute());
            const endDateTime = dayjs(selectedDate).hour(dayjs(selectedEndDateTime).hour()).minute(dayjs(selectedEndDateTime).minute());

            const startDateTimeISO = startDateTime.toISOString();
            const endDateTimeISO = endDateTime.toISOString();

            const mappedAppointment = mapValuesToAppointmentRegistration(
                startDateTimeISO,
                endDateTimeISO,
                selectedDentist.value,
                selectedPatient.patient_external_id,
                description,
                status
            );

            await createAppointment(user.access_token, mappedAppointment);

            showSuccessToast(`Consulta para ${dayjs(selectedStartDateTime).locale('pt').format('dddd, DD/MM/YYYY [às] HH:mm')} criada com sucesso.`, 4000);
            const month = moment(chosenDateFromCalendar).format('MMMM').toUpperCase();
            const year = moment(chosenDateFromCalendar).year();
            handleFormSubmit(month, year);
            closeConfirmationDialog();
            cleanForms();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleDatePickerChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleConfirmationDialog = () => {

        const selectedStartHour = dayjs(selectedStartDateTime).hour();
        const selectedEndHour = dayjs(selectedEndDateTime).hour();

        var formError = false;

        if (!selectedStartDateTime || dayjs(selectedStartDateTime).isSame(dayjs(selectedStartDateTime).startOf('day'))) {
            setStartTimeError('Horário de início é obrigatório');
            formError = true;
        } else {
            setStartTimeError('');
        }

        if (!selectedEndDateTime || dayjs(selectedEndDateTime).isSame(dayjs(selectedEndDateTime).startOf('day'))) {
            setEndTimeError('Horário de fim é obrigatório');
            formError = true;
        } else if (selectedEndHour < selectedStartHour) {
            setEndTimeError('Horário de fim não pode ser antes do horário de início');
            formError = true;
        } else {
            setEndTimeError('');
        }

        if (!selectedDentist) {
            setDentistError('Médico dentista é obrigatório');
            formError = true;
        } else {
            setDentistError('');
        }

        if (!selectedPatient) {
            setPatientError('Paciente é obrigatório');
            formError = true;
        } else {
            setPatientError('');
        }

        if (!formError) {
            setConfirmationDialogOpen(true);
        }
    }

    return (
        <Dialog
            open={open}
            style={{ width: '100%' }}
            maxWidth={false}
            PaperProps={{
                style: {
                    width: '650px',
                    margin: 'auto',
                },
            }}
        >
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleConfirmationDialog}
                >
                    <Form id="patientForm" className="default-form">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="personal-data" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                                    <IconButton color="inherit" aria-label="menu">
                                        <EventIcon />
                                    </IconButton>
                                    Criar consulta para {dayjs(selectedDate).locale('pt').format('dddd, LL')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <br />

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <FormControl style={{ width: '100%' }} margin="normal">
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="pt"
                                        style={{ width: '100%' }}
                                        margin="normal"
                                    >
                                        <DatePicker
                                            label="Data da consulta"
                                            format="DD/MM/YYYY"
                                            defaultValue={dayjs(chosenDateFromCalendar)}
                                            onChange={date => handleDatePickerChange(date)}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormControl style={{ width: '100%' }} margin="normal">
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="pt"
                                        style={{ width: '100%' }}
                                        margin="normal"
                                    >
                                        <TimePicker
                                            label="Inicio"
                                            ampm={false}
                                            value={dayjs(selectedStartDateTime)}
                                            onChange={(newValue) => handleStartTimeChange(newValue)}
                                        />
                                    </LocalizationProvider>
                                    {startTimeError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{startTimeError}</div>}
                                </FormControl>
                            </Grid>


                            <Grid item xs={12} sm={3}>
                                <FormControl style={{ width: '100%' }} margin="normal">
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="pt"
                                        style={{ width: '100%' }}
                                        margin="normal"
                                    >
                                        <TimePicker
                                            label="Fim"
                                            ampm={false}
                                            value={dayjs(selectedEndDateTime)}
                                            onChange={(newValue) => handleEndTimeChange(newValue)}
                                        />
                                    </LocalizationProvider>
                                    {endTimeError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{endTimeError}</div>}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={7}>
                                <FormControl style={{ width: '100%' }} margin="normal">
                                    <InputLabel id="status-label">Status da consulta</InputLabel>
                                    <Field
                                        name="status"
                                        as={Select}
                                        labelId="status-label"
                                        defaultValue="NOT_CONFIRMED"
                                        value={status}
                                        onChange={(event) => setStatus(event.target.value)}
                                        label="Status da consulta"
                                    >
                                        <MenuItem value="NOT_CONFIRMED">
                                            <ListItem disablePadding>
                                                <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                                    <WarningIcon style={{ color: 'yellow' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Criado / Não confirmado" style={{ margin: '0' }} />
                                            </ListItem>
                                        </MenuItem>
                                        <MenuItem value="CONFIRMED">
                                            <ListItem disablePadding>
                                                <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                                    <CheckIcon style={{ color: 'green' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Confirmado" style={{ margin: '0' }} />
                                            </ListItem>
                                        </MenuItem>
                                        <MenuItem value="CANCELED_RESCHEDULED">
                                            <ListItem disablePadding>
                                                <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                                    <EventRepeatIcon style={{ color: 'orange' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Cancelado / Remarcado" style={{ margin: '0' }} />
                                            </ListItem>
                                        </MenuItem>
                                        <MenuItem value="CANCELED_NOT_RESCHEDULED">
                                            <ListItem disablePadding>
                                                <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                                    <CancelIcon style={{ color: 'red' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Cancelado / Não remarcado" style={{ margin: '0' }} />
                                            </ListItem>
                                        </MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <FormControl style={{ width: '100%' }} margin="normal">
                                    <InputLabel id="select-dentist">Médico dentista</InputLabel>
                                    <Field
                                        as={Select}
                                        name="dentist"
                                        labelId="doctor-label"
                                        value={selectedDentist.value}
                                        onChange={handleDentistChange}
                                        label="Médico dentista"
                                        displayEmpty
                                        defaultValue=""
                                    >
                                        {dentistsDropdownOptions.map((option) => (
                                            <MenuItem
                                                name="dentist"
                                                key={option.value}
                                                value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    {dentistError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{dentistError}</div>}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={7}>
                                <FormControl style={{ width: '100%' }} margin="normal">
                                    <Autocomplete
                                        value={selectedPatient}
                                        onChange={(event, query) => setSelectedPatient(query)}
                                        inputValue={searchText}
                                        onInputChange={handleSearchChange}
                                        options={searchResults}
                                        getOptionLabel={(option) => option ? (option.taxpayer_number ? `${option.taxpayer_number} - ${option.name}` : `${option.name}`) : ''}
                                        noOptionsText="Não encontrado"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Paciente"
                                                variant="outlined"
                                                placeholder={"340785409 - Joana Ferreira"}
                                            />
                                        )}
                                        PaperComponent={({ children }) => {
                                            return (
                                                <Paper>
                                                    {children}
                                                    <Button
                                                        color="primary"
                                                        style={{ width: '100%' }}
                                                        sx={{ justifyContent: "flex-start", pl: 2 }}
                                                        onMouseDown={() => {
                                                            openCreatePatientDialog()
                                                        }}
                                                    >
                                                        Registrar novo paciente
                                                    </Button>
                                                </Paper>
                                            );
                                        }}
                                    />
                                    {patientError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{patientError}</div>}
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Textarea
                                    name="description"
                                    style={{ width: '100%' }}
                                    margin="normal"
                                    slots={{ textarea: InnerTextarea }}
                                    slotProps={{ textarea: { placeholder: 'Paciente precisa confirmar o agendamento ...' } }}
                                    sx={{ borderRadius: '6px' }}
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <div style={{ margin: '20px 0' }}></div>
                        <DialogActions>
                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={2}>
                                    <Button onClick={handleCloseDialog}
                                        variant="contained"
                                        style={{ backgroundColor: 'white', color: 'black' }}
                                    >
                                        Cancelar
                                    </Button>
                                </Grid>
                                <div style={{ marginLeft: '40px' }}></div>
                                <Grid item xs={10} sm={8}>
                                    <Button type="submit"
                                        variant="contained"
                                        style={{ backgroundColor: 'darkgreen', color: 'white' }}
                                    >
                                        Criar consulta
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
            <QuickCreatePatientDialog
                open={createPatientDialogOpen}
                handleClose={() => setCreatePatientDialogOpen(false)}
                onPatientCreated={handlePatientCreated}
            />
            <CreateEventConfirmationDialog
                open={confirmationDialogOpen}
                onClose={closeConfirmationDialog}
                selectedDate={selectedDate}
                selectedStartDateTime={selectedStartDateTime}
                selectedEndDateTime={selectedEndDateTime}
                selectedDentist={selectedDentist}
                selectedPatient={selectedPatient}
                description={description}
                status={status}
                handleConfirmCreation={handleConfirmCreation}
            />

        </Dialog >
    );
};

export default CreateEventDialog;
