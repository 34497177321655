import dayjs from 'dayjs';
import { isGMTPlusOne } from '../helpers/DateTimeHelper';

export const mapValuesToAppointmentRegistration = (
    startEventDateTime, endEventDateTime, dentistId, patientId, description, status
) => {

    let startDateTime = dayjs(startEventDateTime);
    let endDateTime = dayjs(endEventDateTime);

    // Check if the timezone is GMT+1 and adjust the dates accordingly
    if (isGMTPlusOne()) {
        startDateTime = startDateTime.add(1, 'hour');
        endDateTime = endDateTime.add(1, 'hour');
    }

    return {
        description: description,
        dentist_id: dentistId,
        patient_id: patientId,
        start_event_date_time: startDateTime.toISOString(),
        end_event_date_time: endDateTime.toISOString(),
        appointment_status: status
    };
};

export default mapValuesToAppointmentRegistration;