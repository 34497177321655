import React, { useState, useEffect } from 'react';
import { FormControl, FormControlLabel, FormGroup, Checkbox, Typography, TextField, Button, Box, InputAdornment, AccordionSummary, AccordionDetails, Autocomplete, CircularProgress } from '@mui/material';
import { Search } from '@mui/icons-material';
import { getUsers } from '../../services/UserService';
import { useNavigate } from 'react-router';
import { handleError } from '../../helpers/ErrorHandling';
import { useUser } from '../../contexts/UserContext';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { getAutocompleteSearchAppointments } from '../../services/ScheduleService';
import ViewEventDialog from './ViewEventDialog';

const rowStyle = {
    display: 'flex',
    alignItems: 'center', // Vertically center align items
};

const textStyle = {
    fontSize: '14px',
};

const eventStatusesOptions = [
    { 
        value: 'NOT_CONFIRMED', 
        label: (
            <div style={rowStyle}>
                <WarningIcon style={{ color: 'yellow' }} /> 
                <span style={textStyle}>Criado / Não confirmado</span>
            </div>
        ) 
    },
    { 
        value: 'CONFIRMED', 
        label: (
            <div style={rowStyle}>
                <CheckIcon style={{ color: 'green' }} /> 
                <span style={textStyle}>Confirmado</span>
            </div>
        ) 
    },
    { 
        value: 'CANCELED_RESCHEDULED', 
        label: (
            <div style={rowStyle}>
                <EventRepeatIcon style={{ color: 'orange' }} /> 
                <span style={textStyle}>Cancelado / Remarcado</span>
            </div>
        ) 
    },    
    { 
        value: 'CANCELED_NOT_RESCHEDULED', 
        label: (
            <div style={rowStyle}>
                <CancelIcon style={{ color: 'red' }} /> 
                <span style={textStyle}>Cancelado / Não Remarcado</span>
            </div>
        ) 
    }
];

const CalendarFilters = ({ setSelectedDentists, selectedDentists, setSelectedEventStatuses, selectedEventStatuses, onDialogClose }) => {
    const { user, clearUser } = useUser();
    const navigate = useNavigate();
    const [dentists, setDentists] = useState([]);
    const [expandedDentistsAccordion, setExpandedDentistsAccordion] = useState(false);
    const [expandedAppointmentStatussAccordion, setExpandedAppointmentStatussAccordion] = useState(false);
    const [eventStatuses, setEventStatuses] = useState([]);
    const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
    const [appointmentOptions, setAppointmentOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [openViewEventDialog, setOpenViewEventDialog] = useState(false);

    const handleAutocompleteSelect = async (event, selectedAppointment) => {
        if (selectedAppointment) {
            try {
                setSelectedAppointment(selectedAppointment);
                setOpenViewEventDialog(true);
            } catch (error) {
                handleError(error, clearUser, navigate);
            }
        }
    };

    const handleViewEventDialogClose = () => {
        setAppointmentOptions([]);
        setAutocompleteInputValue('');
        setSelectedAppointment(null);
        setOpenViewEventDialog(false);
        onDialogClose();
    };

    const handleFormSubmit = (month, year) => {
        setOpenViewEventDialog(false);
    };

    const handleDentistExpansion = () => {
        setExpandedDentistsAccordion((prevExpanded) => !prevExpanded);
    };

    const handleStatusExpansion = () => {
        setExpandedAppointmentStatussAccordion((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {
        const fetchDentistsData = async () => {
            try {
                const response = await getUsers(user.access_token, 0, 10000, 'DENTIST');
                const appointmentOptions = response.data.content.map((user) => ({
                    value: user.user_external_id,
                    label: `${user.first_name} ${user.last_name}`,
                    color: user.primary_color
                }));
                setDentists(appointmentOptions);
                if (selectedDentists.length === 0) {
                    setSelectedDentists(appointmentOptions.map(dentist => dentist.value));
                }
            } catch (error) {
                handleError(error, clearUser, navigate);
            }
        };

        setSelectedEventStatuses(eventStatusesOptions.map(status => status.value));
        setEventStatuses(eventStatusesOptions);
        fetchDentistsData();
    }, []);


    useEffect(() => {
        let active = true;

        const fetchData = async () => {
            setLoading(true);
            const data = await fetchAutocompleteOptions(autocompleteInputValue);
            if (active) {
                setAppointmentOptions(data);
                setLoading(false);
            }
        };

        if (autocompleteInputValue && autocompleteInputValue.trim().length > 0) {
            fetchData();
        } else {
            setAppointmentOptions([]);
            setLoading(false);
        }

        return () => {
            active = false;
        };
    }, [autocompleteInputValue]);

    const handleDentistChange = (event) => {
        const { value } = event.target;
        setSelectedDentists((prevSelected) => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter((dentist) => dentist !== value);
            } else {
                return [...prevSelected, value];
            }
        });
    };

    const handleEventStatusChange = (event) => {
        const { value } = event.target;
        setSelectedEventStatuses((prevSelected) => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter((status) => status !== value);
            } else {
                return [...prevSelected, value];
            }
        });
    };

    const handleCleanFilters = () => {
        setAutocompleteInputValue('');
        setSelectedDentists(dentists.map(dentist => dentist.value));
        setSelectedEventStatuses(eventStatusesOptions.map(status => status.value));
    };

    const fetchAutocompleteOptions = async (keyword) => {

        // Set a minimum number of caracteres to fetch data from backend side
        if (keyword.length < 3) {
            return [];
        }

        const response = await getAutocompleteSearchAppointments(user.access_token, keyword);
        return response.data.content.map(item => {
            const startDate = new Date(item.start_event_date_time);
            const formattedStartDate = startDate.toLocaleString('pt', {
                day: '2-digit',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            });

            return {
                id: item.appointment_external_id,
                label: `${formattedStartDate}: ${item.patient_summary.patient_abbreviate_name} - ${item.dentist_summary.dentist_name}`,
                appointment_status: item.appointment_status,
                start: startDate,
                end: new Date(item.end_event_date_time),
                backgroundColor: item.dentist_summary.primary_color,
                dentistName: item.dentist_summary.dentist_name,
                patientName: item.patient_summary.patient_name,
            };
        });
    };

    return (
        <Box sx={{
            padding: '20px',
            position: 'fixed',
            left: 70,
            top: 70,
            bottom: 0,
            zIndex: 1000,
            backgroundColor: 'white',
            overflowY: 'auto',
            width: '300px',
        }}>
            <Typography variant="h5" gutterBottom>Filtros</Typography>

            <FormControl fullWidth margin="normal" size="small" sx={{ marginBottom: '20px' }}>
                <Autocomplete
                    fullWidth
                    freeSolo
                    options={appointmentOptions}
                    inputValue={autocompleteInputValue}
                    onInputChange={(event, newInputValue) => {
                        setAutocompleteInputValue(newInputValue);
                    }}
                    onChange={handleAutocompleteSelect}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Procurar consulta"
                            variant="outlined"
                            placeholder="Nome paciente ou dentista"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                            size="small"
                        />
                    )}
                />
                {!loading && autocompleteInputValue.length > 3 && !appointmentOptions.length && !selectedAppointment && (
                    <Typography variant="body2" color="error">Consulta não encontrada</Typography>
                )}
            </FormControl>

            <Accordion
                expanded={expandedDentistsAccordion}
                onChange={handleDentistExpansion}
                sx={{
                    '& .MuiAccordion-region': { height: expandedDentistsAccordion ? 'auto' : 0 },
                    '& .MuiAccordionDetails-root': { display: expandedDentistsAccordion ? 'block' : 'none' },
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Médicos dentistas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() => setSelectedDentists([])}
                        fullWidth
                        style={{ marginTop: '1px' }}
                        size="small"
                    >
                        <Typography variant="body2" style={{ fontSize: '12px' }}>Desmarcar todos</Typography>
                    </Button>

                    <FormControl component="fieldset" fullWidth margin="normal">
                        <FormGroup>
                            {dentists.map((dentist) => (
                                <FormControlLabel
                                    key={dentist.value}
                                    control={
                                        <Checkbox
                                            checked={selectedDentists.includes(dentist.value)}
                                            onChange={handleDentistChange}
                                            value={dentist.value}
                                            style={{
                                                color: dentist.color,
                                                '&.MuiChecked': {
                                                    color: dentist.color,
                                                }
                                            }}
                                        />
                                    }
                                    label={dentist.label}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expandedAppointmentStatussAccordion}
                onChange={handleStatusExpansion}
                sx={{
                    marginTop: '10px',
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Status das consultas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl component="fieldset" fullWidth margin="normal">
                        <FormGroup>
                            {eventStatuses.map((status) => (
                                <FormControlLabel
                                    key={status.value}
                                    control={
                                        <Checkbox
                                            checked={selectedEventStatuses.includes(status.value)}
                                            onChange={handleEventStatusChange}
                                            value={status.value}
                                        />
                                    }
                                    label={status.label}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>

            <FormControl component="fieldset" fullWidth margin="normal">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCleanFilters}
                    fullWidth
                    style={{ backgroundColor: 'grey', color: 'white', width: '180px', height: '30px' }}
                    center="true"
                >
                    Resetar filtro
                </Button>
            </FormControl>
            {selectedAppointment && (
                <ViewEventDialog
                    open={openViewEventDialog}
                    handleClose={handleViewEventDialogClose}
                    selectedAppointment={selectedAppointment}
                    handleFormSubmit={(month, year) => handleFormSubmit(month, year)}
                />
            )}
        </Box>
    );
};

export default CalendarFilters;
