import React, { useState } from 'react';
import Modal from 'react-modal';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1000'
  },
  content: {
    textAlign: 'center',
    top: 300,
    left: 0,
    right: 0,
    bottom: 280,
    maxWidth: '200px',
    width: '100%',
    margin: 'auto',
    padding: '50px',
    background: 'white',
    borderRadius: '8px',
  }
};

const SuccessModal = ({ isOpen, onClose, successMessage }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Success Modal"
      style={modalStyles}
    >
      <h2 style={{ textAlign: 'center' }}>Sucesso!</h2>
      <p>{successMessage}</p>
      <br />
      <button
        onClick={onClose}
        style={{
          backgroundColor: 'darkgreen',
          color: 'white',
          padding: '10px',
          width: '50%',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        OK
      </button>
    </Modal>
  );
};


export const ResetPasswordModal = ({ isOpen, onClose }) => {

  const [username, setUsername] = useState('');

  const handleResetPassword = () => {
    // Implement the logic to initiate the password reset process
    console.log(`Initiate password reset for ${username}`);
    // Close the modal
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Success Modal"
      style={modalStyles}
    >
      <h2 style={{ textAlign: 'center' }}>Resete sua senha!</h2>
      <p>Digite o utilizador para redefinir sua senha. <br />Um e-mail será enviado para você com as instruções.</p>

      <input
        type="text"
        placeholder="Utilizador"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <br />
      <br />
      <button onClick={handleResetPassword}
        variant="contained"
        style={{ backgroundColor: 'darkgreen', color: 'white', width: '150px', height: '40px' }}
        center="true">
        Reset Password
      </button>
    </Modal>
  );
};


export default SuccessModal;
