import AdminSidebar from '../components/dashboard/sidebar/AdminSidebar';
import EmployeeSidebar from '../components/dashboard/sidebar/EmployeeSidebar';
import DentistSidebar from '../components/dashboard/sidebar/DentistSidebar';

export const mapUserTypeToText = (enumValue) => {
    switch (enumValue) {
        case 'CLINIC_OWNER':
            return 'Administrador da clínica';
        case 'EMPLOYEE':
            return 'Funcionário';
        case 'DENTIST':
            return 'Médico dentista';
        default:
            return enumValue;
    }
};

export const mapUserStatusToText = (enumValue) => {
    switch (enumValue) {
        case 'ACTIVE':
            return 'Ativo';
        case 'INACTIVE':
            return 'Inativo';
        case 'DENTIST':
            return 'Médico dentista';
        default:
            return enumValue;
    }
};

export const mapUserTypeToSidebar = (enumValue) => {
    switch (enumValue) {
        case 'CLINIC_OWNER':
            return AdminSidebar;
        case 'EMPLOYEE':
            return EmployeeSidebar;
        default:
            return DentistSidebar;
    }
};

export const mapAppointmentStatusToText = (status) => {
    switch (status) {
        case 'NOT_CONFIRMED':
            return 'Criado / Não confirmado';
        case 'CONFIRMED':
            return 'Confirmado';
        case 'CANCELED_NOT_RESCHEDULED':
            return 'Cancelado / Não Remarcado';
        case 'CANCELED_RESCHEDULED':
            return 'Cancelado / Remarcado';            
        default:
            return 'Desconhecido';
    }
};