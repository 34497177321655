import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showToast = (message, type = 'default', options = {}) => {
  toast[type](message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options
  });
  
};

export const showErrorToast = (message, autoClose = 3000, options = {}) => {
  showToast(message, 'error', { autoClose, ...options });
};

export const showSuccessToast = (message, autoClose = 3000, options = {}) => {
  showToast(message, 'success', { autoClose, ...options });
};

export const showWarnToast = (message, autoClose = 3000, options = {}) => {
  showToast(message, 'warn', { autoClose, ...options });
};

export default showToast;
