import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Formik, Form, Field } from 'formik';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import FormError from '../../helpers/FormError';
import { useUser } from '../../contexts/UserContext';
import { shortSignUpValidationSchema } from '../../helpers/ValidationSchemaHelper';
import { useNavigate } from 'react-router-dom';
import MaskedTextField from '../../helpers/MaskHelper';
import CreateUserConfirmationDialog from './CreateUserConfirmationDialog';
import { handleError } from '../../helpers/ErrorHandling';
import { mapFormValuesToUserRegistrationV2 } from '../../data/userRegistration';
import { signup } from '../../services/SignupService';
import { showSuccessToast } from '../../helpers/ToastHelper';

const initialValues = {
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    phoneNumber: '',
    street: '',
    locale: '',
    city: '',
    zipCode: ''
};

const CreateUserDialog = ({ open, onClose, handleFetchUsers }) => {
    const { clearUser } = useUser();
    const navigate = useNavigate();
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [userDetails, setUserDetails] = useState('');

    const handleCreateUser = async (values) => {
        setUserDetails(values);
        setConfirmationDialogOpen(true);
    };

    const closeConfirmationDialog = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmCreation = async (username, password) => {

        try {

            const mappedUserRegistration = mapFormValuesToUserRegistrationV2(userDetails, username, password);

            await signup(mappedUserRegistration);

            showSuccessToast(`Utilizador ${userDetails.firstName} criado com sucesso.`);

            closeConfirmationDialog();
            handleFetchUsers();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} >
            <Formik
                initialValues={initialValues}
                validationSchema={shortSignUpValidationSchema}
                onSubmit={handleCreateUser}
            >
                {() => (
                    <Form className="default-form">
                        <Typography variant="personal-data" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                            Cadastrar utilizador - dados pessoais:
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="firstName"
                                    label="Primeiro nome"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                <FormError name="firstName" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="lastName"
                                    label="Último nome"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                <FormError name="lastName" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl variant="outlined" fullWidth margin="normal">
                                    <InputLabel id="role-label">Perfil de utilizador</InputLabel>
                                    <Field
                                        name="role"
                                        as={Select}
                                        labelId="role-label"
                                        displayEmpty
                                        label="Perfil de utilizador"
                                    >
                                        <MenuItem value="CLINIC_OWNER">Admin</MenuItem>
                                        <MenuItem value="EMPLOYEE">Funcionário</MenuItem>
                                        <MenuItem value="DENTIST">Médico dentista</MenuItem>
                                    </Field>
                                </FormControl>
                                <FormError name="role" />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="email"
                                    label="E-mail"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                <FormError name="email" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <MaskedTextField
                                    name="phoneNumber"
                                    label="Telemóvel"
                                    mask="999 999 999"
                                />
                                <FormError name="phoneNumber" />
                            </Grid>

                        </Grid>

                        <br />
                        <Typography variant="address" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                            Informações da morada:
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={7}>
                                <Field
                                    type="text"
                                    name="street"
                                    label="Morada"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                <FormError name="street" />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <Field
                                    type="text"
                                    name="complement"
                                    label="Complemento"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                <FormError name="complement" />
                            </Grid>

                        </Grid>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="locale"
                                    label="Localidade"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                <FormError name="locale" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="city"
                                    label="Cidade"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                <FormError name="city" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Field
                                    type="text"
                                    name="zipCode"
                                    label="Código postal"
                                    as={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                <FormError name="zipCode" />
                            </Grid>

                        </Grid>

                        <br />

                        <Grid container spacing={2}>
                            <Grid item xs={10} sm={2}>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: 'white', color: 'black' }}
                                    center=""
                                    onClick={onClose}
                                >
                                    Voltar
                                </Button>
                            </Grid>

                            <div style={{ marginLeft: '20px' }}></div>

                            <Grid item xs={10} sm={4}>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: 'darkgreen', color: 'white' }}
                                    type="submit"
                                >
                                    Cadastrar
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <CreateUserConfirmationDialog
                open={confirmationDialogOpen}
                onClose={closeConfirmationDialog}
                userDetails={userDetails}
                handleConfirmCreation={handleConfirmCreation}
            />
        </Dialog>
    );
};

export default CreateUserDialog;
