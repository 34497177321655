import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/user-service/api/v1`

const api = axios.create({
  baseURL: API_BASE_URL
});

export const getUsers = async (token, pageNumber = 0, pageSize = 10000, userType = '') => {
  try {
    const authorizationHeader = `Bearer ${token}`;

    const response = await api.get(`/user?pageNumber=${pageNumber}&pageSize=${pageSize}&userType=${userType}`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserDetails = async (token, userId) => {
  try {
    const authorizationHeader = `Bearer ${token}`;

    const response = await api.get(`/user/${userId}`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (token, userId) => {
  try {
    const authorizationHeader = `Bearer ${token}`;

    const response = await api.delete(`/user/${userId}`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserStatus = async (token, userId, updateUserStatus) => {
  try {
    const authorizationHeader = `Bearer ${token}`;

    const response = await api.put(`/user/${userId}/status`, updateUserStatus, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserColor = async (token, userId, updateUserColor) => {
  try {
    const authorizationHeader = `Bearer ${token}`;

    const response = await api.put(`/user/${userId}/color`, updateUserColor, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserDetails = async (token, userId, userRegistration) => {
  try {
    const authorizationHeader = `Bearer ${token}`;

    const response = await api.put(`/user/${userId}`, userRegistration, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserPassword = async (token, userId, updateUserPassword) => {
  try {
    const authorizationHeader = `Bearer ${token}`;

    const response = await api.put(`/user/${userId}/update-password`, updateUserPassword, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetUserPassword = async (token, userId) => {
  try {
    const authorizationHeader = `Bearer ${token}`;

    const response = await api.put(`/user/${userId}/reset-password`, null ,{
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};