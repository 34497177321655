import { AccessTime, AccountBox, Event, Group, Info, LockClock, WorkHistory } from "@mui/icons-material";
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { mapAppointmentStatusToText } from "../../helpers/EnumHelper";

const CreateEventConfirmationDialog = ({ open, onClose, selectedDate, selectedStartDateTime, selectedEndDateTime, selectedDentist, selectedPatient, description, status, handleConfirmCreation }) => {
    const [isDialogOpened, setIsDialogOpened] = useState(false);

    useEffect(() => {
        if (open) {
            setIsDialogOpened(true);
        }
    }, [open]);

    const handleDialogClose = () => {
        setIsDialogOpened(false);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
        >
            <DialogTitle id="confirmation-dialog-title" style={{ fontWeight: 'bold', textAlign: 'center', color: 'green' }}>
                <Info style={{ color: 'green', verticalAlign: 'text-top' }} />
                &nbsp; Confirmar marcação de consulta
            </DialogTitle>
            <DialogContent>
                {isDialogOpened && (
                    <>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <Event />
                            </IconButton>
                            <strong>Data:</strong> {dayjs(selectedDate).locale('pt').format('dddd, LL')}
                        </Typography>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <AccessTime />
                            </IconButton>
                            <strong>Horário inicio:</strong> {`${dayjs(selectedStartDateTime).format('HH:mm')} horas`}
                        </Typography>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <AccessTime />
                            </IconButton>
                            <strong>Horário fim:</strong> {`${dayjs(selectedEndDateTime).format('HH:mm')} horas`}
                        </Typography>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <Group />
                            </IconButton>
                            <strong>Médico Dentista:</strong> {selectedDentist.label}
                        </Typography>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <AccountBox />
                            </IconButton>
                            <strong>Paciente:</strong> {selectedPatient.name}
                        </Typography>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <WorkHistory />
                            </IconButton>
                            <strong>Status:</strong> {mapAppointmentStatusToText(status)}
                        </Typography>
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={handleDialogClose}
                    variant="contained"
                    style={{ backgroundColor: 'white', color: 'black' }}
                >
                    Voltar
                </Button>
                <Button onClick={() => handleConfirmCreation(selectedDate, selectedStartDateTime, selectedEndDateTime, selectedDentist, selectedPatient, description, status)} color="success" variant="contained">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateEventConfirmationDialog;