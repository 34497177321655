// UserContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

const localStorageName = 'dental-user'

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState(() => {
        // Try to get user from localStorage on initial load
        const storedUser = localStorage.getItem(localStorageName);
        return storedUser ? JSON.parse(storedUser) : null;
    });

    useEffect(() => {
        // Save user to localStorage whenever it changes
        localStorage.setItem(localStorageName, JSON.stringify(user));
    }, [user]);

    const updateUser = (newUserData) => {
        setUser(newUserData);
    };

    const clearUser = () => {
        setUser(null);
    };

    return (
        <UserContext.Provider value={{ user, updateUser, clearUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};