import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DentalDashboard from '../dashboard/DentalDashboard.js';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUser } from '../../contexts/UserContext.js';
import { Avatar, Container, Divider, TablePagination, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../helpers/ErrorHandling.js';
import { getAutocompleteSearchAudits } from '../../services/AuditService.js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const DeleteIconWrapper = styled(DeleteIcon)`
  cursor: pointer;
  color: red;
`;

const VisibilityIconWrapper = styled(Visibility)`
  cursor: pointer;
  color: grey;
`;

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    let initials = '';
    const nameArray = name.split(' ');

    if (nameArray.length > 1) {
        initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    } else {
        initials = name.substring(0, 2).toUpperCase();
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initials,
    };
}

const formatDateTime = (dateTimeString) => {
    const [date, time] = dateTimeString.split(' ');
    const [year, month, day] = date.split('/');
    return `${day}/${month}/${year} ${time}`;
};

const Audit = () => {
    const [rows, setRows] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { user, clearUser } = useUser();
    const token = user?.access_token || null;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    const fetchData = async (query = '', currentPage = page, currentRowsPerPage = rowsPerPage) => {

        // Set a minimum number of caracteres to fetch data from backend side
        if (query.length != 0 && query.length < 3) {
            return [];
        }

        try {
            const response = await getAutocompleteSearchAudits(token, currentPage, currentRowsPerPage);
            const content = response.data.content || [];
            const totalElements = response.data.total_elements || 0;
            const totalPages = Math.ceil(totalElements / currentRowsPerPage);

            setRows(content);
            setTotalPages(totalPages);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or when the search query changes with a delay
        const fetchDataTimeout = setTimeout(() => {
            fetchData(searchQuery || '', page, rowsPerPage);
        }, 300);

        // Clear the timeout on component unmount or when searchQuery, page, or rowsPerPage changes
        return () => clearTimeout(fetchDataTimeout);
    }, [searchQuery, page, rowsPerPage]);


    const handleChangePage = (event, newPage) => {
        // Update page and fetch data
        setPage(newPage);
        fetchData(searchQuery, newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // Update rowsPerPage and fetch data
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        fetchData(searchQuery, 0, newRowsPerPage);
    };

    const filteredRows = rows.filter(
        (row) =>
            row.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.feature_type.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <DentalDashboard>
            <Container maxWidth="lg" sx={{ marginTop: 4 }}>
                <TableContainer sx={{ maxWidth: 900, margin: '16px auto 0' }}>
                    <Typography
                        variant="h4"
                        noWrap
                        component="div"
                        align="left"
                        sx={{
                            fontFamily: 'Arial, sans-serif',
                            fontWeight: 'bold',
                            color: 'darkgreen',
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                            letterSpacing: '1px',
                            marginBottom: '50px',
                        }}
                    >
                        Auditoria do sistema
                    </Typography>
                    <Divider style={{ marginBottom: '30px' }} />
                    <Table sx={{ minWidth: 200 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">ID registro</StyledTableCell>
                                <StyledTableCell align="center">Nome do utilizador</StyledTableCell>
                                <StyledTableCell align="center">Ação</StyledTableCell>
                                <StyledTableCell align="center">Data / hora</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(0, rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row) => (
                                <StyledTableRow key={row.patient_external_id}>
                                    <StyledTableCell align="center">{row.access_tracking_external_id.split("-")[0]}</StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar {...stringAvatar(`${row.user_name}`)} />
                                            <span style={{ marginLeft: '10px' }}>{row.user_name}</span>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.feature_type}</StyledTableCell>
                                    <StyledTableCell align="center">{formatDateTime(row.created_at)}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalPages * rowsPerPage}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Rows per page:"
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                    />
                </TableContainer>
                <br />
            </Container>
        </DentalDashboard>
    );
};

export default Audit;
