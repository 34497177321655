import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ViewEditPatient from './ViewEditPatient';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Typography } from '@mui/material';
import MedicalAppointmentsMainPage from './medical-appointments/MedicalAppointmentsMainPage';
import PatientRecordMainPage from './patient-record/PatientRecordMainPage';

const MainPatientDialog = ({ open, onClose, patient }) => {

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth={false} maxWidth='xl'>
            <DialogContent style={{ height: '850px', width: '850px', overflowY: 'auto' }}>
                <Box bgcolor="darkgreen" color="white" p={2}>
                    <Typography variant="h7"><strong>Paciente:</strong> {patient.name} - <strong>Contribuinte (NIF):</strong> {patient.taxpayerNumber}</Typography>
                </Box>
                <TabContext value={value}>
                    <Box sx={{ width: 600 }}>
                        <TabList onChange={handleChange} aria-label="Patient options">
                            <Tab icon={<AccountBoxIcon />} label="Dados do Paciente" value="1" style={{ fontSize: '14px', textTransform: 'none' }} />
                            {/* <Tab icon={<ChecklistIcon />} label="Anamnese" value="2" style={{ fontSize: '14px', textTransform: 'none' }} /> */}
                            <Tab icon={<EventNoteIcon />} label="Histórico de consultas" value="3" style={{ fontSize: '14px', textTransform: 'none' }} />
                            <Tab icon={<AssignmentIcon />} label="Fichas do paciente" value="4" style={{ fontSize: '14px', textTransform: 'none' }} />
                        </TabList>
                    </Box>
                    <TabPanel value="1"><ViewEditPatient open={open} onClose={onClose} patient={patient} /></TabPanel>
                    {/* <TabPanel value="2"><AnamnesisMainPage patient={patient} /></TabPanel> */}
                    <TabPanel value="3"><MedicalAppointmentsMainPage patient={patient} /></TabPanel>
                    <TabPanel value="4"><PatientRecordMainPage patient={patient} /></TabPanel>
                </TabContext>
            </DialogContent>
        </Dialog>
    );
};

export default MainPatientDialog;
