import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    IconButton,
    TextField,
} from '@mui/material';
import { Login } from '@mui/icons-material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { resetPasswordValidationSchema } from '../../helpers/ValidationSchemaHelper';
import FormError from '../../helpers/FormError';
import { useUser } from '../../contexts/UserContext';
import { showSuccessToast } from '../../helpers/ToastHelper';
import { handleError } from '../../helpers/ErrorHandling';
import { useNavigate } from 'react-router-dom';
import { updateUserPassword } from '../../services/UserService';
import { mapFormValuesToUpdatePassword } from '../../data/updateUserPassword';

const HomeUpdatePasswordDialog = ({ oldPassword, open, onClose, user }) => {

    const navigate = useNavigate();
    const { clearUser } = useUser();

    const handleUpdatePassword = async (values, { setSubmitting }) => {
        try {

            values.oldPassword = oldPassword;
            const mappedUserPassword = mapFormValuesToUpdatePassword(values);
            await updateUserPassword(user.access_token, user.user_external_id, mappedUserPassword);

            showSuccessToast('Senha atualizada com sucesso, por favor realize o login novamente', 5000)
            clearUser();
            onClose();

        } catch (error) {
            handleError(error, clearUser, navigate);
        }
        setSubmitting(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialogTitle-root': {
                    color: 'darkgreen',
                },
                '& .MuiDialogActions-root': {
                    justifyContent: 'center',
                },
                '& .MuiDialogContentText-root': {
                    color: 'black',
                },
            }}
        >
            <Formik
                initialValues={{ newPassword: '', confirmNewPassword: '' }}
                validationSchema={resetPasswordValidationSchema}
                onSubmit={handleUpdatePassword}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <DialogTitle><Login /> Primeiro acesso no sistema!</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Olá <strong>{user?.first_name} {user?.last_name}</strong>, seu utilizador foi criado e agora você precisa definir sua nova senha para ter acesso ao sistema.</DialogContentText>
                            <br />
                            <Field
                                as={TextField}
                                name="newPassword"
                                label="Nova Senha"
                                type="password"
                                size="medium"
                                fullWidth
                                sx={{ marginBottom: '8px', marginRight: '15px' }}
                            />
                            <FormError name="newPassword" />
                            <Field
                                as={TextField}
                                name="confirmNewPassword"
                                label="Confirmação de Nova Senha"
                                type="password"
                                size="medium"
                                fullWidth
                                sx={{ marginBottom: '8px' }}
                            />
                            <FormError name="confirmNewPassword" />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="secondary">
                                Cancelar
                            </Button>
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default HomeUpdatePasswordDialog;
