import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/schedule-service/api/v1`

const api = axios.create({
    baseURL: API_BASE_URL
});

export const getAppointments = async (token, month = null, year = null, patientId = null, responseModel = 'FULL') => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        let queryParams;
        if (patientId !== null) {
            queryParams = `&patientId=${patientId}`;
        } else {
            queryParams = `month=${month}&year=${year}`;
        }

        const response = await api.get(`/medical-appointment?${queryParams}&responseModel=${responseModel}`, {
            headers: {
                Authorization: authorizationHeader
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getAppointmentDetails = async (token, appointmentExternalId) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.get(`/medical-appointment/${appointmentExternalId}`, {
            headers: {
                Authorization: authorizationHeader
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createAppointment = async (token, appointmentRegistration) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.post(`/medical-appointment`, appointmentRegistration, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const updateAppointment = async (token, appointmentExternalId, appointmentRegistration) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.put(`/medical-appointment/${appointmentExternalId}`, appointmentRegistration, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const deleteAppointment = async (token, appointmentExternalId) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.delete(`/medical-appointment/${appointmentExternalId}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAutocompleteSearchAppointments = async (token, keyword) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.get(`/medical-appointment/autocomplete?keyword=${keyword}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDentistAppointmentDetailsList = async (token, dentistExternalId, initialDate, endDate) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.get(`/medical-appointment/dentist/${dentistExternalId}?initialDate=${initialDate}&endDate=${endDate}`, {
            headers: {
                Authorization: authorizationHeader
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};