// MainComponent.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { mapUserTypeToSidebar } from '../../helpers/EnumHelper';

const DentalDashboard = ({ children }) => {
    document.title = 'Dashboard - Sorriso Soberano';

    const { user } = useUser();

    if (!user) {
        return <Navigate to="/" />;
    }

    const profile = user.user_type_name;
    const SidebarComponent = mapUserTypeToSidebar(profile);

    return (
        <div style={{ display: 'flex' }}>
            <SidebarComponent />
            <main style={{ flex: 1, padding: '50px' }}>{children}</main>
        </div>
    );
};

export default DentalDashboard;
