import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/user-service/api/v1/user`

const api = axios.create({
  baseURL: API_BASE_URL
});

export const signup = async (userRegistration) => {
  try {
    const response = await api.post('', userRegistration);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const generateSignUpId = async (token, userType) => {
  try {
    const authorizationHeader = `Bearer ${token}`;

    const response = await api.post(`/signup/id`, { "user_type": userType }, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const validateSignUpId = async (signupId) => {
  try {
    const response = await api.post(`/signup/verify`, { "signup_id": signupId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const publicSignup = async (userRegistration) => {
  try {
    const response = await api.post('/signup', userRegistration);
    return response.data;
  } catch (error) {
    throw error;
  }
};

