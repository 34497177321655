import { AssignmentInd, Info, Password, Person, Work } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import 'dayjs/locale/pt';
import { mapUserTypeToText } from "../../helpers/EnumHelper";
import { temporaryPassword } from "../../helpers/StringHelper";

const CreateUserConfirmationDialog = ({ open, onClose, userDetails, handleConfirmCreation }) => {
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    const [username, setUsername] = useState('');

    useEffect(() => {
        if (open) {
            setIsDialogOpened(true);
            setUsername(`${userDetails.firstName.toLowerCase()}.${userDetails.lastName.toLowerCase()}`);
        }
    }, [open]);

    const handleDialogClose = () => {
        setUsername('');
        setIsDialogOpened(false);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
        >
            <DialogTitle id="confirmation-dialog-title" style={{ fontWeight: 'bold', textAlign: 'center', color: 'green' }}>
                <Info style={{ color: 'green', verticalAlign: 'text-top' }} />
                &nbsp; Confirmar criação de utilizador
            </DialogTitle>
            <DialogContent>
                {isDialogOpened && (
                    <>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <Person />
                            </IconButton>
                            <strong>Nome do utilizador:</strong> {`${userDetails.firstName} ${userDetails.lastName}`}
                        </Typography>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <Work />
                            </IconButton>
                            <strong>Perfil:</strong> {mapUserTypeToText(userDetails.role)}
                        </Typography>
                        <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <AssignmentInd />
                            </IconButton>
                            <strong>Utilizador:</strong> {username}
                        </Typography>
                        <Typography variant="body1" style={{ marginBottom: 8 }}>
                            <IconButton color="inherit" aria-label="menu">
                                <Password />
                            </IconButton>
                            <strong>Senha temporária:</strong> {temporaryPassword}
                        </Typography>
                        <div style={{ margin: '20px 0' }}></div>
                        Após confirmar, o utilizador ja terá acesso ao sistema. Basta informa-lo para utilizar seu <strong>username</strong> e <strong>senha temporária</strong> mencionados acima.
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={handleDialogClose}
                    variant="contained"
                    style={{ backgroundColor: 'white', color: 'black' }}
                >
                    Voltar
                </Button>
                <Button
                    onClick={() => handleConfirmCreation(username, temporaryPassword)}
                    color="success" variant="contained">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateUserConfirmationDialog;