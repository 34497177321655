import React from 'react';
import Sidebar from './Sidebar';
import {
  Groups2 as GroupIcon,
} from '@mui/icons-material';
import { PATIENT_MANAGEMENT_PATH } from '../../../Constants';
import { useNavigate } from 'react-router-dom';

const title = 'Sorriso Soberano - Funcionário';

const UserSidebar = () => {

  const navigate = useNavigate();

  const items = [
    {
      text: 'Pacientes',
      icon: <GroupIcon />,
      onClick: () => navigate(PATIENT_MANAGEMENT_PATH),
    }
  ];

  return <Sidebar items={items} title={title} />;
};

export default UserSidebar;
