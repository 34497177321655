import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DentalDashboard from '../dashboard/DentalDashboard.js';
import { getUsers, resetUserPassword, updateUserStatus } from '../../services/UserService.js';
import { useUser } from '../../contexts/UserContext.js';
import { mapUserTypeToText } from '../../helpers/EnumHelper.js';
import { showSuccessToast } from '../../helpers/ToastHelper.js';
import ConfirmationDialog from '../../helpers/ConfirmationDialog.js';
import { mapFormValuesToUserStatus } from '../../data/updateUserStatus.js'
import { Avatar, Button, Container, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { handleError } from '../../helpers/ErrorHandling.js';
import { useNavigate } from 'react-router-dom';
import { Add, Check, Clear, Password, ToggleOn, ColorLens } from '@mui/icons-material';
import CreateUserDialog from './CreateUserDialog.js';
import ColorPickerDialog from './ColorPickerDialog.js';
import GenerateLinkCreateUserDialog from './GenerateLinkCreateUserDialog.js';
import { temporaryPassword } from '../../helpers/StringHelper.js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const InactiveIconWrapper = styled(Clear)`
    color: red;
`;

const ActiveIconWrapper = styled(Check)`
    color: green;
`;

const ChangeStatusIconWrapper = styled(ToggleOn)`
    cursor: pointer;
    color: black;
    font-size: 32px;
`;

const ResetPasswordIconWrapper = styled(Password)`
    cursor: pointer;
    color: black;
    font-size: 32px;
`;

const ColorLensIconWrapper = styled(ColorLens)`
    cursor: pointer;
    color: black;
    font-size: 32px;
`;

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const UsersManagement = () => {
    const [rows, setRows] = useState([]);
    const { user, clearUser } = useUser();
    const token = user?.access_token;
    const [activeDialogOpen, setActiveDialogOpen] = useState(false);
    const [generateLinkDialogOpen, setGenerateLinkDialogOpen] = useState(false);
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
    const [updateColorDialogOpen, setUpdateColorDialogOpen] = useState(false);
    const [selectedUser, setselectedUser] = useState({});
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await getUsers(token);
            const userList = response.data.content || [];
            setRows(userList);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdateStatus = (id, firstName, lastName, userStatus) => {
        const newStatusEnum = userStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        const newStatusMessage = userStatus === 'ACTIVE' ? 'Inativo' : 'Ativo';
        setselectedUser({
            id,
            firstName,
            lastName,
            newStatusEnum,
            newStatusMessage
        });
        setActiveDialogOpen(true);
    };

    const handleCloseActiveDialog = () => {
        setActiveDialogOpen(false);
        setselectedUser({});
    };

    const handleResetPassword = (id, firstName, lastName) => {
        setselectedUser({
            id,
            firstName,
            lastName
        });
        setResetPasswordDialogOpen(true);
    };

    const handleUpdateColor = (id, firstName, lastName, color) => {
        setselectedUser({
            id,
            firstName,
            lastName,
            color
        });
        setUpdateColorDialogOpen(true);
    };

    const handleCloseResetPasswordDialog = () => {
        setResetPasswordDialogOpen(false);
        setselectedUser({});
    };

    const handleCloseUpdateColorDialog = () => {
        setUpdateColorDialogOpen(false);
        setselectedUser({});
        fetchData();
    };

    const handleConfirmActive = async () => {
        try {
            const updateUserStatusRequest = mapFormValuesToUserStatus(selectedUser.newStatusEnum)
            await updateUserStatus(token, selectedUser.id, updateUserStatusRequest);
            showSuccessToast(`Status do utilizador ${selectedUser.firstName} ${selectedUser.lastName} atualizado com sucesso.`, 2500);
            fetchData();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
        handleCloseActiveDialog();
    };

    const handleConfirmResetPassword = async () => {
        try {
            await resetUserPassword(token, selectedUser.id);
            showSuccessToast(`Senha do utilizador ${selectedUser.firstName} ${selectedUser.lastName} resetada com sucesso.`, 2500);
            fetchData();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
        handleCloseResetPasswordDialog();
    };

    const handleOpenAddDialog = () => {
        setAddDialogOpen(true);
    };

    const handleCloseAddDialog = () => {
        setAddDialogOpen(false);
    };

    const handleFetchUsers = () => {
        fetchData();
        setAddDialogOpen(false);
    };

    const handleOpenGenerateLinkDialog = () => {
        setGenerateLinkDialogOpen(true);
    };

    const handleCloseGenerateLinkDialog = () => {
        setGenerateLinkDialogOpen(false);
    };

    return (
        <DentalDashboard>
            <Container maxWidth="lg" sx={{ marginTop: 4 }}>
                <TableContainer sx={{ maxWidth: 1000, margin: '16px auto 0' }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Typography
                            variant="h4"
                            noWrap
                            component="div"
                            align="left"
                            sx={{
                                fontFamily: 'Arial, sans-serif',
                                fontWeight: 'bold',
                                color: 'darkgreen',
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                                letterSpacing: '1px',
                                marginBottom: '50px',
                            }}
                        >
                            Gerenciar Utilizadores do sistema
                        </Typography>
                        <br />
                        <Grid item>
                            <Button
                                align="right"
                                variant="contained"
                                style={{ backgroundColor: 'darkgreen', color: 'white', textTransform: 'none', marginRight: '10px' }}
                                onClick={handleOpenAddDialog}
                                startIcon={<Add />}
                            >
                                Novo utilizador
                            </Button>
                            <Button
                                align="right"
                                variant="contained"
                                style={{ backgroundColor: 'darkgreen', color: 'white', textTransform: 'none' }}
                                onClick={handleOpenGenerateLinkDialog}
                                startIcon={<Add />}
                            >
                                Gerar link
                            </Button>
                        </Grid>
                    </Grid>
                    <CreateUserDialog
                        open={addDialogOpen}
                        onClose={handleCloseAddDialog}
                        handleFetchUsers={handleFetchUsers}
                    />
                    <GenerateLinkCreateUserDialog
                        open={generateLinkDialogOpen}
                        onClose={handleCloseGenerateLinkDialog}
                    />
                    <Divider style={{ marginBottom: '30px' }} />
                    <Table sx={{ minWidth: 200 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Nome do usúario</StyledTableCell>
                                <StyledTableCell align="center">Utilizador</StyledTableCell>
                                <StyledTableCell align="center">Perfil de usuário</StyledTableCell>
                                <StyledTableCell align="center">Status da conta</StyledTableCell>
                                <StyledTableCell align="center">Ações</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.user_external_id}>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar {...stringAvatar(`${row.first_name} ${row.last_name}`)} />
                                            <span style={{ marginLeft: '10px' }}>{row.first_name} {row.last_name}</span>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.username}</StyledTableCell>
                                    <StyledTableCell align="center">{mapUserTypeToText(row.user_type_name)}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.user_status === 'ACTIVE' ? (
                                            <React.Fragment>
                                                <ActiveIconWrapper />
                                                <span>Ativa</span>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <InactiveIconWrapper />
                                                <span>Inativa</span>
                                            </React.Fragment>
                                        )}
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                        <Tooltip title="Alterar status da conta">
                                            <ChangeStatusIconWrapper onClick={() => handleUpdateStatus(row.user_external_id, row.first_name, row.last_name, row.user_status)} />
                                        </Tooltip>
                                        {row.user_type_name === 'DENTIST' && (
                                            <Tooltip title="Alterar cor do utilizador">
                                                <ColorLensIconWrapper onClick={() => handleUpdateColor(row.user_external_id, row.first_name, row.last_name, row.primary_color)} />
                                            </Tooltip>
                                        )}
                                        <Tooltip title="Resetar senha do utilizador">
                                            <ResetPasswordIconWrapper onClick={() => handleResetPassword(row.user_external_id, row.first_name, row.last_name)} />
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <ConfirmationDialog
                            open={activeDialogOpen}
                            onClose={handleCloseActiveDialog}
                            onConfirm={handleConfirmActive}
                            title="Atualizar status da conta"
                            contentText={`Tem certeza de que deseja atualizar ${selectedUser.firstName} ${selectedUser.lastName} para ${selectedUser.newStatusMessage}?`}
                        />
                        <ConfirmationDialog
                            open={resetPasswordDialogOpen}
                            onClose={handleCloseResetPasswordDialog}
                            onConfirm={handleConfirmResetPassword}
                            title="Reset de senha do utilizador"
                            //contentText={`Tem certeza de que deseja resetar a senha do utilizador ${selectedUser.firstName} ${selectedUser.lastName}?`}
                            contentText={
                                <div>
                                    Tem certeza de que deseja resetar a senha do utilizador {selectedUser.firstName} {selectedUser.lastName}?

                                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                                        <IconButton color="inherit" aria-label="menu">
                                            <Password />
                                        </IconButton>
                                        <strong>Será definida a senha temporária:</strong> {temporaryPassword}
                                    </Typography>
                                </div>
                            }
                        />
                        <ColorPickerDialog
                            userInfo={selectedUser}
                            open={updateColorDialogOpen}
                            onClose={handleCloseUpdateColorDialog}
                        />
                    </Table>
                </TableContainer>
            </Container>
        </DentalDashboard>
    );
};

export default UsersManagement;