import React from 'react';
import { useField } from 'formik';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';

const MaskedTextField = ({ mask, label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (e) => {
    helpers.setValue(e.target.value);
  };

  const isMaskComplete = () => {
    const maskedValue = field.value ? field.value.replace(/[^0-9]/g, '') : '';
    return maskedValue.length === mask.replace(/[^0-9]/g, '').length;
  };

  return (
    <InputMask
      mask={mask}
      maskChar={null}
      value={field.value}
      onBlur={field.onBlur}
      onChange={handleChange}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          {...props}
          label={label}
          variant="outlined"
          fullWidth
          margin="normal"
          helperText={(meta.touched && meta.error) || !isMaskComplete() ? 'Formato inválido' : null}
        />
      )}
    </InputMask>
    
  );
};


export default MaskedTextField;
