// Signup.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, TextField, Button, Grid, MenuItem, Select, FormControl, InputLabel, CircularProgress } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import '../styles/styles.css';
import { useLocation, useNavigate } from "react-router-dom";
import MaskedTextField from '../helpers/MaskHelper.js';
import { publicSignup, signup, validateSignUpId } from '../services/SignupService.js';
import { mapFormValuesToUserRegistration } from '../data/userRegistration.js';
import { showErrorToast } from '../helpers/ToastHelper.js';
import { mapErrorCodeToMessage } from '../helpers/ErrorCodeMessageHelper.js';
import SuccessModal from '../helpers/ModalHelper.js';
import FormError from '../helpers/FormError.js';
import { signUpValidationSchema } from '../helpers/ValidationSchemaHelper.js';
import { Error } from '@mui/icons-material';
import { mapUserTypeToText } from '../helpers/EnumHelper.js';
import { useUser } from '../contexts/UserContext.js';

const initialValues = {
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    street: '',
    locale: '',
    city: '',
    zipCode: ''
};

const Signup = () => {

    document.title = 'Cadastro';
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const signUpId = queryParams.get('signUpId');
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isAllowed, setIsAllowed] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [userType, setUserType] = useState('');

    const closeModal = () => {
        setIsModalOpen(false);
        setSuccessMessage('');
        navigate('/');
    };

    useEffect(() => {

        const isUUID = (id) => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id);

        if (!signUpId || !isUUID(signUpId)) {
            setErrorMessage('Link inválido. Por favor, verifique e tente novamente.');
            setIsAllowed(false);
            return;
        }

        const verifySignUp = async () => {
            try {
                const response = await validateSignUpId(signUpId);
                setUserType(response.data.user_type)
                setIsAllowed(true);
            } catch (error) {
                const errorMessage = mapErrorCodeToMessage(error);
                setErrorMessage(errorMessage);
                setIsAllowed(false);
            }
        };

        verifySignUp();
    }, [signUpId]);

    const handleSignup = async (values, { setSubmitting }) => {
        try {
            await signUpValidationSchema.validate(values, { abortEarly: false });
            const mappedUserRegistration = mapFormValuesToUserRegistration(values, signUpId, userType);

            await publicSignup(mappedUserRegistration);

            setSuccessMessage('Utilizador criado com sucesso, acesse com seu utilizador e senha.');
            setIsModalOpen(true);

        } catch (error) {
            const errorMessage = mapErrorCodeToMessage(error);
            showErrorToast(errorMessage, 5000);
        }

        setSubmitting(false);
    };

    if (!isAllowed) {
        return (
            <div className="signup-form-container">
                <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Error color="error" style={{ fontSize: 100 }} />
                    <Typography variant="h4" align="center" gutterBottom style={{ marginTop: '20px' }}>
                        {errorMessage}
                    </Typography>
                    <br />
                    <Button
                        variant="contained"
                        style={{ backgroundColor: 'darkgreen', color: 'white' }}
                        center="true"
                        type="submit"
                        onClick={() => navigate('/')}
                    >
                        Voltar para página inicial
                    </Button>
                </Container>
            </div>
        );
    }

    if (isAllowed === null) {
        return (
            <Container maxWidth="sm">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '60vh' }}>
                    <CircularProgress />
                </div>
            </Container>
        );
    }

    return (

        <div className="signup-content">
            <div className="signup-form-container">

                <Container maxWidth="sm" className="signup-content">
                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        className="signup-heading"
                        style={{ color: 'darkgreen' }}
                    >
                        Sorriso soberano <br /> Cadastre-se como {mapUserTypeToText(userType)}
                    </Typography>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={signUpValidationSchema}
                        onSubmit={handleSignup}
                    >
                        {() => (
                            <Form className="default-form">
                                <Typography variant="signup-data" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                                    Dados para seu Login:
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            type="text"
                                            name="username"
                                            label="Utilizador"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="username" />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            type="password"
                                            name="password"
                                            label="Senha"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="password" />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            type="password"
                                            name="confirmPassword"
                                            label="Confimação senha"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="confirmPassword" />
                                    </Grid>
                                </Grid>

                                <br />
                                <Typography variant="personal-data" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                                    Dados pessoais:
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            type="text"
                                            name="firstName"
                                            label="Primeiro nome"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="firstName" />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            type="text"
                                            name="lastName"
                                            label="Último nome"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="lastName" />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            type="text"
                                            name="email"
                                            label="E-mail"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="email" />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <MaskedTextField
                                            name="phoneNumber"
                                            label="Telemóvel"
                                            mask="999 999 999"
                                        />
                                        <FormError name="phoneNumber" />
                                    </Grid>

                                </Grid>

                                <br />
                                <Typography variant="address" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                                    Informações da morada:
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={7}>
                                        <Field
                                            type="text"
                                            name="street"
                                            label="Morada"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="street" />
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <Field
                                            type="text"
                                            name="complement"
                                            label="Complemento"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="complement" />
                                    </Grid>

                                </Grid>

                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            type="text"
                                            name="locale"
                                            label="Localidade"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="locale" />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            type="text"
                                            name="city"
                                            label="Cidade"
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                        <FormError name="city" />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <MaskedTextField
                                            name="zipCode"
                                            label="Código Postal"
                                            mask="9999-999"
                                        />
                                        <FormError name="zipCode" />
                                    </Grid>

                                </Grid>

                                <br />

                                <Grid container spacing={2}>
                                    <Grid item xs={10} sm={2}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: 'white', color: 'black' }}
                                            center=""
                                            onClick={() => navigate(-1)}
                                        >
                                            Fechar
                                        </Button>
                                    </Grid>

                                    <div style={{ marginLeft: '20px' }}></div>

                                    <Grid item xs={10} sm={4}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: 'darkgreen', color: 'white' }}
                                            type="submit"
                                        >
                                            Cadastrar
                                        </Button>
                                        <SuccessModal isOpen={isModalOpen} onClose={closeModal} successMessage={successMessage} />

                                    </Grid>

                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </div>
        </div>
    );
};

export default Signup;