const updateUserStatus = {
    user_status: 'string'
};

export const mapFormValuesToUserStatus = (userStatus) => {
    return {
        user_status: userStatus
    }
};

export default updateUserStatus;