import React, { useState, useEffect } from 'react';
import { Typography, Divider, Grid, FormControl, InputLabel, MenuItem, Select, Button, Modal, Box, List, ListItem, ListItemText, IconButton, Tooltip, Stack, Container } from '@mui/material';
import DentalDashboard from '../dashboard/DentalDashboard.js';
import { useUser } from '../../contexts/UserContext.js';
import { handleError } from '../../helpers/ErrorHandling.js';
import { getUsers } from '../../services/UserService.js';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import { Groups2 as GroupIcon, Info, CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/pt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { showErrorToast, showWarnToast } from '../../helpers/ToastHelper.js';
import MainPatientDialog from '../patient-management/MainPatientDialog.js';
import { getDentistAppointmentDetailsList } from '../../services/ScheduleService.js';
import dayjs from 'dayjs';

const PerformanceAnalysis = () => {
    const [selectedDentist, setSelectedDentist] = useState('');
    const [appointmentsData, setAppointmentsData] = useState(null);
    const [dentistsDropdownOptions, setDentistsDropdownOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewEditDialogOpen, setViewEditDialogOpen] = useState(false);
    const [selectedPatientRecord, setSelectedPatientRecord] = useState(null);
    const { user, clearUser } = useUser();
    const token = user?.access_token || null;
    const navigate = useNavigate();
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    useEffect(() => {
        const fetchDentists = async () => {
            try {
                let options;
                if (user.user_type_name === 'DENTIST') {
                    options = [{ value: user.user_external_id, label: `${user.first_name} ${user.last_name}` }];
                } else {
                    const response = await getUsers(token, 0, 10000, 'DENTIST');
                    options = response.data.content.map((user) => ({
                        value: user.user_external_id,
                        label: `${user.first_name} ${user.last_name}`,
                    }));
                }
                setDentistsDropdownOptions(options);
            } catch (error) {
                handleError(error, clearUser, navigate);
            }
        };
        if (token) fetchDentists();
    }, [token, clearUser, navigate]);

    const handleDentistChange = (event) => {
        const selectedDentistId = event.target.value;
        const dentist = dentistsDropdownOptions.find(option => option.value === selectedDentistId) || { label: 'Não selecionado', value: null };
        setSelectedDentist(dentist);
    };

    const handleStartDateTimePickerChange = (newDate) => {
        setSelectedStartDate(newDate);
    };

    const handleEndDateTimePickerChange = (newDate) => {
        setSelectedEndDate(newDate);
    };

    const handleSearch = async () => {
        if (!selectedDentist || !selectedStartDate || !selectedEndDate) {
            showErrorToast("Preencha todas as informações");
            return;
        }

        const formattedStartDate = dayjs(selectedStartDate).locale('pt').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const formattedEndDate = dayjs(selectedEndDate).endOf('day').locale('pt').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        try {
            const response = await getDentistAppointmentDetailsList(user.access_token, selectedDentist.value, formattedStartDate, formattedEndDate);
            if (response.data.patient_count === 0 && response.data.consult_count === 0) {
                showWarnToast("Não foram encontrados dados para essa consulta");
                setAppointmentsData(null);
            } else {
                setAppointmentsData(response.data);
            }
        } catch (error) {
            console.log(error)
            handleError(error, clearUser, navigate);
        }
    };

    const handleQuickDateSelection = (option) => {
        let startDate, endDate;
        const today = dayjs();
        switch (option) {
            case 'currentMonth':
                startDate = today.startOf('month');
                endDate = today.endOf('month');
                break;
            case 'lastMonth':
                startDate = today.subtract(1, 'month').startOf('month');
                endDate = today.subtract(1, 'month').endOf('month');
                break;
            case 'last3Months':
                startDate = today.subtract(3, 'months').startOf('month');
                endDate = today.endOf('month');
                break;
            case 'currentYear':
                startDate = today.startOf('year');
                endDate = today.endOf('year');
                break;
            default:
                break;
        }
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    };

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleViewEditDialogOpen = (patient) => {
        setSelectedPatientRecord(patient);
        setViewEditDialogOpen(true);
    };

    const handleViewEditDialogClose = () => {
        setSelectedPatientRecord(null);
        setViewEditDialogOpen(false);
    };

    return (
        <DentalDashboard>
            <Container maxWidth="lg" sx={{ marginTop: 4 }}>
                <Typography
                    variant="h4"
                    noWrap
                    component="div"
                    align="left"
                    sx={{
                        fontFamily: 'Arial, sans-serif',
                        fontWeight: 'bold',
                        color: 'darkgreen',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                        letterSpacing: '1px',
                        marginBottom: '50px',
                    }}
                >
                    Análise e desempenho
                </Typography>
                <Divider sx={{ marginBottom: '10px' }} />
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Médico dentista</InputLabel>
                            <Select label="Médico dentista" value={selectedDentist.value || ''} onChange={handleDentistChange}>
                                {dentistsDropdownOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                            <DatePicker label="Data início" value={selectedStartDate} onChange={handleStartDateTimePickerChange} fullWidth />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                            <DatePicker label="Data fim" value={selectedEndDate} onChange={handleEndDateTimePickerChange} fullWidth />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Button variant="contained" color="inherit" onClick={() => handleQuickDateSelection('currentMonth')}>
                                Mês Atual
                            </Button>
                            <Button variant="contained" color="inherit" onClick={() => handleQuickDateSelection('lastMonth')}>
                                Último Mês
                            </Button>
                            <Button variant="contained" color="inherit" onClick={() => handleQuickDateSelection('last3Months')}>
                                Últimos 3 Meses
                            </Button>
                            <Button variant="contained" color="inherit" onClick={() => handleQuickDateSelection('currentYear')}>
                                Ano Atual
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button variant="contained" color="success" onClick={handleSearch}>Pesquisar</Button>
                    </Grid>
                </Grid>
                <div>
                    {appointmentsData ? (
                        <Grid container spacing={2} justifyContent="left" sx={{ marginTop: '24px' }}>
                            <Grid item xs={12} sm={10} md={4}>
                                <Card variant="solid" color="primary" invertedColors>
                                    <CardContent orientation="horizontal">
                                        <CircularProgress size="lg" determinate value={appointmentsData.patient_count}>
                                            <GroupIcon />
                                        </CircularProgress>
                                        <CardContent>
                                            <Typography level="body-md">Número de pacientes atendidos</Typography>
                                            <Typography level="h2">{appointmentsData.patient_count}</Typography>
                                        </CardContent>
                                    </CardContent>
                                    <CardActions>
                                        <Button variant="solid" size="sm" onClick={handleOpenModal}>
                                            Detalhar pacientes
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={10} md={4}>
                                <Card variant="solid" color="neutral" invertedColors>
                                    <CardContent orientation="horizontal">
                                        <CircularProgress size="lg" determinate value={appointmentsData.consult_count}>
                                            <CalendarMonthIcon />
                                        </CircularProgress>
                                        <CardContent>
                                            <Typography level="body-md">Número de atendimentos</Typography>
                                            <Typography level="h2">{appointmentsData.consult_count}</Typography>
                                        </CardContent>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    ) : (null)}
                </div>

                <Modal open={isModalOpen} onClose={handleCloseModal}>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 1, width: 300 }}>
                        <Typography variant="h6" gutterBottom>Lista de Pacientes</Typography>
                        <List>
                            {appointmentsData?.patient_detail_list.map((patient) => (
                                <ListItem key={patient.patient_external_id} secondaryAction={
                                    <IconButton edge="end" onClick={() => handleViewEditDialogOpen(
                                        {
                                            id: patient.patient_external_id,
                                            name: patient.name,
                                            taxpayerNumber: patient.taxpayer_number
                                        }
                                    )}>
                                        <Tooltip title={"Consultas detalhes do paciente"}>
                                            <Info />
                                        </Tooltip>
                                    </IconButton>
                                }>
                                    <ListItemText primary={patient.name} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Modal>

                {selectedPatientRecord && (
                    <MainPatientDialog
                        open={viewEditDialogOpen}
                        onClose={handleViewEditDialogClose}
                        patient={selectedPatientRecord}
                    />
                )}
            </Container>
        </DentalDashboard>
    );
};

export default PerformanceAnalysis;
