import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/patient-service/api/v1`

const api = axios.create({
    baseURL: API_BASE_URL
});

export const getPatients = async (token, pageNumber, pageSize) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.get(`/patient?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getPatientDetails = async (token, patientId) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.get(`/patient/${patientId}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deletePatient = async (token, patientId) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.delete(`/patient/${patientId}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updatePatientDetails = async (token, patientId, patientRegistration) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.put(`/patient/${patientId}`, patientRegistration, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAutocompleteSearchPatients = async (token, keyword, pageNumber, pageSize) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.get(`/patient/autocomplete?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createPatient = async (token, patientRegistration) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.post(`/patient`, patientRegistration, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

