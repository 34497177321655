import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Formik, Form, Field } from 'formik';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import FormError from '../../helpers/FormError';
import { useUser } from '../../contexts/UserContext';
import { generateSignupLinkValidationSchema } from '../../helpers/ValidationSchemaHelper';
import { useNavigate } from 'react-router-dom';
import { generateSignUpId } from '../../services/SignupService';
import { showSuccessToast } from '../../helpers/ToastHelper';
import { handleError } from '../../helpers/ErrorHandling';
import { ContentCopy } from '@mui/icons-material';

const initialValues = {
    role: ''
};

const GenerateLinkCreateUserDialog = ({ open, onClose }) => {
    const { user, clearUser } = useUser();
    const token = user.access_token;
    const navigate = useNavigate();
    const [signupIdDialogOpen, setSignupIdDialogOpen] = useState(false);
    const [signupUrl, setSignupUrl] = useState('');

    const handleGenerateSignupId = async (values) => {
        const id = await handleConfirmCreation(values.role);
        if (id) {
            const hostName = window.location.origin;
            const url = `${hostName}/sign-up?signUpId=${id}`;
            setSignupUrl(url);
            setSignupIdDialogOpen(true);
        }
    };

    const handleConfirmCreation = async (userType) => {
        try {
            const response = await generateSignUpId(token, userType);
            return response.data.signup_id;
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const closeSignupIdDialog = () => {
        setSignupIdDialogOpen(false);
        onClose(); // Closes all dialogs after user dismisses the ID dialog
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(signupUrl);
        showSuccessToast('URL copiada para a área de transferência!');
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={generateSignupLinkValidationSchema}
                    onSubmit={handleGenerateSignupId}
                >
                    {() => (
                        <Form className="default-form">
                            <Typography variant="h6" gutterBottom align="left" style={{ fontWeight: 'bold', color: 'green' }}>
                                Link para criação do utilizador
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Uma URL de criação de novo utilizador com validade de 24 horas será gerada e deve ser enviada para a pessoa que fará o cadastro. Lembre-se de copiar o link assim que for exibido.
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={5}>
                                    <FormControl variant="outlined" fullWidth margin="normal">
                                        <InputLabel id="role-label">Perfil de utilizador</InputLabel>
                                        <Field
                                            name="role"
                                            as={Select}
                                            labelId="role-label"
                                            label="Perfil de utilizador"
                                        >
                                            <MenuItem value="CLINIC_OWNER">Admin</MenuItem>
                                            <MenuItem value="EMPLOYEE">Funcionário</MenuItem>
                                            <MenuItem value="DENTIST">Médico dentista</MenuItem>
                                        </Field>
                                    </FormControl>
                                    <FormError name="role" />
                                </Grid>
                            </Grid>

                            <div style={{ margin: '50px 0' }}></div>

                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid container spacing={2}>
                                    <Grid item xs={10} sm={2}>
                                        <Button onClick={onClose}
                                            variant="contained"
                                            style={{ backgroundColor: 'white', color: 'black' }}
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <div style={{ marginLeft: '40px' }}></div>
                                    <Grid item xs={10} sm={8}>
                                        <Button type="submit"
                                            variant="contained"
                                            style={{ backgroundColor: 'darkgreen', color: 'white' }}
                                        >
                                            Criar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Dialog>

            <Dialog open={signupIdDialogOpen} onClose={closeSignupIdDialog}>
                <div style={{ padding: '20px', textAlign: 'center', maxWidth: '500px' }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: 'green' }}>
                        Link para Criação de Utilizador
                    </Typography>

                    <Typography variant="body1" style={{ margin: '20px 0', color: '#555' }}>
                        Use o link abaixo para criar o utilizador. Clique no ícone para copiar a URL.
                    </Typography>

                    <a
                        href={signupUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#007bff',
                            textDecoration: 'none',
                            wordBreak: 'break-word',
                            display: 'block',
                            padding: '10px',
                            backgroundColor: '#f1f1f1',
                            borderRadius: '5px',
                            marginBottom: '20px'
                        }}
                    >
                        {signupUrl}
                    </a>

                    <div style={{ marginBottom: '20px' }}>
                        <IconButton
                            onClick={copyToClipboard}
                            aria-label="Copy ID to clipboard"
                            style={{ fontSize: '24px', color: '#555' }}
                        >
                            <ContentCopy />
                        </IconButton>
                        <Typography variant="caption" display="block" gutterBottom>
                            Clique no ícone para copiar a URL
                        </Typography>
                    </div>
                    <Button
                        variant="contained"
                        onClick={closeSignupIdDialog}
                        style={{
                            backgroundColor: 'darkgreen',
                            color: 'white',
                            marginTop: '20px',
                            width: '100%',
                            fontWeight: 'bold'
                        }}
                    >
                        Fechar
                    </Button>
                </div>
            </Dialog>

        </>
    );
};

export default GenerateLinkCreateUserDialog;
