import * as Yup from 'yup';

export const loginValidationSchema = Yup.object({
  username: Yup.string().required('Utilizador é obrigatorio'),
  password: Yup.string()
    .required('Senha é obrigatoria')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      `Senha precisa conter pelo menos 8 caracteres, sendo letras e numeros`
    ),
});

export const resetPasswordValidationSchema = Yup.object({
  newPassword: Yup.string()
    .required('Nova Senha é obrigatoria')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      `Senha precisa seguir o critério de pelo menos 8 caracteres, contento letras e numeros`
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Nova Senha e confirmação de nova senha precisam ser iguais')
    .required('Confirmação de senha é obrigatoria')
});

export const signUpValidationSchema = Yup.object({
  username: Yup.string().required('Utilizador é obrigatorio'),
  password: Yup.string()
    .required('Senha é obrigatoria')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      `Senha precisa seguir o critério de pelo menos 8 caracteres, contento letras e numeros`
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senha e confirmação de senha precisam ser iguais')
    .required('Confirmação de senha é obrigatoria'),
  firstName: Yup.string().required('Primeiro nome é obrigatorio'),
  lastName: Yup.string().required('Ultimo nome é obrigatorio'),
  email: Yup.string().email('E-mail precisa ter formato válido').required('E-mail é obrigatorio'),
  phoneNumber: Yup.string().required('Telemóvel é obrigatorio'),
  street: Yup.string().required('Morada é obrigatoria'),
  locale: Yup.string().required('Localidade é obrigatorio'),
  city: Yup.string().required('Cidade é obrigatoria'),
  zipCode: Yup.string().required('Código Postal é obrigatorio')
});

export const shortSignUpValidationSchema = Yup.object({
  firstName: Yup.string().required('Primeiro nome é obrigatorio'),
  lastName: Yup.string().required('Ultimo nome é obrigatorio'),
  role: Yup.string().required('Perfil de úsuario é obrigatorio'),
  email: Yup.string().email('E-mail precisa ter formato válido').required('E-mail é obrigatorio'),
  phoneNumber: Yup.string().required('Telemóvel é obrigatorio'),
  street: Yup.string().required('Morada é obrigatoria'),
  locale: Yup.string().required('Localidade é obrigatorio'),
  city: Yup.string().required('Cidade é obrigatoria'),
  zipCode: Yup.string().required('Código Postal é obrigatorio')
});

export const generateSignupLinkValidationSchema = Yup.object({
  role: Yup.string().required('Perfil de úsuario é obrigatorio')
});

export const updateProfileSchema = Yup.object({
  firstName: Yup.string().max(50, 'Primeiro nome deve ter no máximo 50 caracteres').required('Primeiro nome é obrigatório'),
  lastName: Yup.string().max(50, 'Ultimo nome deve ter no máximo 50 caracteres').required('Ultimo nome é obrigatório'),
  email: Yup.string().email('E-mail precisa ter formato válido').required('E-mail é obrigatório'),
  phoneNumber: Yup.string().max(255, 'Telemóvel deve ter no máximo 255 caracteres').required('Telemóvel é obrigatório'),
  street: Yup.string().max(255, 'Morada deve ter no máximo 255 caracteres').required('Morada é obrigatória'),
  locale: Yup.string().max(255, 'Localidade deve ter no máximo 255 caracteres').required('Localidade é obrigatória'),
  city: Yup.string().max(255, 'Cidade deve ter no máximo 255 caracteres').required('Cidade é obrigatória'),
  zipCode: Yup.string().max(50, 'Código Postal deve ter no máximo 50 caracteres').required('Código Postal é obrigatório')
});


export const updatePasswordValidationSchema = Yup.object({
  oldPassword: Yup.string()
    .required('Senha atual é obrigatoria')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      `Senha precisa seguir o critério de pelo menos 8 caracteres, contento letras e numeros`
    ),
  newPassword: Yup.string()
    .required('Nova Senha é obrigatoria')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      `Senha precisa seguir o critério de pelo menos 8 caracteres, contento letras e numeros`
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Nova Senha e confirmação de nova senha precisam ser iguais')
    .required('Confirmação de senha é obrigatoria')
})

export const patientsValidationSchema = Yup.object({
  invoicingId: Yup.string().max(50, 'ID faturamento deve ter no máximo 50 caracteres'),
  name: Yup.string().max(255, 'Nome deve ter no máximo 255 caracteres').required('Nome é obrigatório'),
  email: Yup.string().email('E-mail inválido').max(100, 'E-mail deve ter no máximo 100 caracteres').required('E-mail é obrigatório'),
  phoneNumber: Yup.string().max(20, 'Telemóvel deve ter no máximo 20 caracteres').required('Telemóvel é obrigatório'),
  profession: Yup.string().max(50, 'Profissão deve ter no máximo 50 caracteres'),
  citizenshipCard: Yup.string().max(50, 'Cartão cidadão deve ter no máximo 50 caracteres'),
  taxpayerNumber: Yup.string().max(20, 'Contribuinte (NIF) deve ter no máximo 20 caracteres').required('NIF  é obrigatório'),
  utenteNumber: Yup.string().max(50, 'Número utente deve ter no máximo 50 caracteres'),
  medicalInsurance: Yup.string().max(50, 'Convenção/Seguro deve ter no máximo 50 caracteres'),
  street: Yup.string().max(255, 'Morada deve ter no máximo 255 caracteres'),
  locality: Yup.string().max(100, 'Localidade deve ter no máximo 100 caracteres'),
  zipCode: Yup.string().max(20, 'Código postal deve ter no máximo 20 caracteres')
});

