import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Button, Grid, Typography, IconButton, InputLabel, FormControl, Select, MenuItem, ListItem, ListItemIcon, ListItemText, CircularProgress, Autocomplete, TextField, Paper, Tooltip } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/joy/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import 'moment/locale/pt';
import { showSuccessToast } from '../../helpers/ToastHelper';
import { handleError } from '../../helpers/ErrorHandling';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Cancel, Check, Delete, Event, Lock, Warning, EventRepeat } from '@mui/icons-material';
import { getUsers } from '../../services/UserService';
import { deleteAppointment, getAppointmentDetails, updateAppointment } from '../../services/ScheduleService';
import { getAutocompleteSearchPatients } from '../../services/PatientService';
import { Textarea } from '@mui/joy';
import CreateEventConfirmationDialog from './CreateEventConfirmationDialog';
import mapValuesToAppointmentRegistration from '../../data/appointmentRegistration';
import QuickCreatePatientDialog from './QuickCreatePatientDialog';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import ConfirmationDialog from '../../helpers/ConfirmationDialog';

moment.locale('pt');

const StyledTextarea = styled(TextareaAutosize)({
    resize: 'none',
    border: 'none',
    minWidth: 0,
    outline: 0,
    padding: 0,
    paddingBlockStart: '1em',
    paddingInlineEnd: `var(--Textarea-paddingInline)`,
    flex: 'auto',
    alignSelf: 'stretch',
    color: 'inherit',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    '&::placeholder': {
        opacity: 0,
        transition: '0.1s ease-out',
    },
    '&:focus::placeholder': {
        opacity: 1,
    },
    '&:focus + textarea + label, &:not(:placeholder-shown) + textarea + label': {
        top: '0.5rem',
        fontSize: '0.75rem',
    },
    '&:focus + textarea + label': {
        color: 'var(--Textarea-focusedHighlight)',
    },
});

const StyledLabel = styled('label')(({ theme }) => ({
    position: 'absolute',
    lineHeight: 1,
    top: 'calc((var(--Textarea-minHeight) - 1em) / 2)',
    color: theme.vars.palette.text.tertiary,
    fontWeight: theme.vars.fontWeight.md,
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

const InnerTextarea = React.forwardRef(function InnerTextarea(props, ref) {
    const id = React.useId();
    return (
        <React.Fragment>
            <StyledTextarea minRows={2} {...props} ref={ref} id={id} />
            <StyledLabel htmlFor={id}>Descrição (opcional)</StyledLabel>
        </React.Fragment>
    );
});

const ViewEventDialog = ({ open, handleClose, selectedAppointment, handleFormSubmit }) => {
    const [dentistError, setDentistError] = useState('');
    const [patientError, setPatientError] = useState('');
    const [startTimeError, setStartTimeError] = useState('');
    const [endTimeError, setEndTimeError] = useState('');
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    const { user, clearUser } = useUser();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(selectedAppointment.start);

    const [selectedStartDateTime, setSelectedStartDateTime] = useState(selectedAppointment.start);
    const [selectedEndDateTime, setSelectedEndDateTime] = useState(selectedAppointment.end);

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedDentist, setSelectedDentist] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [dentistDetails, setDentistDetails] = useState(null);
    const [description, setDescription] = useState('');
    const [dentistsDropdownOptions, setDentistsDropdownOptions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [createPatientDialogOpen, setCreatePatientDialogOpen] = useState(false);
    const [allowedEditEventUserType, setAllowedEditEventUserType] = useState(false);

    useEffect(() => {
        if (open && !initialized) {
            if (user.user_type_name !== 'DENTIST') {
                setAllowedEditEventUserType(true);
            }

            fetchDentistsDropdownOptions();
            fetchAppointmentDetails();
            setInitialized(true);
        }

    }, [open]);

    useEffect(() => {

        if (loading && dentistsDropdownOptions.length > 0) {
            const selectedDentistId = dentistDetails?.user_external_id || '';
            let dentist = dentistsDropdownOptions.find(option => option.value === selectedDentistId);
            setSelectedDentist(dentist);
        }

        if (
            selectedDentist != null &&
            selectedPatient != null &&
            selectedDate != null &&
            selectedStatus != null
        ) {
            setLoading(false);
        }
    });

    const toggleEditMode = () => {
        setReadOnly(!readOnly);
    }

    const openCreatePatientDialog = () => {
        setCreatePatientDialogOpen(true);
    };

    const handleDateTimePickerChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const closeConfirmationDialog = () => {
        setConfirmationDialogOpen(false);
    };

    const openDeleteDialog = () => {
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteAppointment(user.access_token, selectedAppointment.id);
            showSuccessToast(`Consulta para ${dayjs(selectedDate).locale('pt').format('dddd, DD/MM/YYYY [às] HH:mm')} removida com sucesso.`, 4000);
            handleFormSubmit(moment(selectedDate).format('MMMM').toUpperCase(), moment(selectedDate).year())
            handleClose();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleUpdateEvent = async () => {
        try {

            const startDateTime = dayjs(selectedDate).hour(dayjs(selectedStartDateTime).hour()).minute(dayjs(selectedStartDateTime).minute());
            const endDateTime = dayjs(selectedDate).hour(dayjs(selectedEndDateTime).hour()).minute(dayjs(selectedEndDateTime).minute());

            const startDateTimeISO = startDateTime.toISOString();
            const endDateTimeISO = endDateTime.toISOString();

            const mappedAppointment = mapValuesToAppointmentRegistration(
                startDateTimeISO,
                endDateTimeISO,
                selectedDentist.value,
                selectedPatient.patient_external_id,
                description,
                selectedStatus
            );
            await updateAppointment(user.access_token, selectedAppointment.id, mappedAppointment);
            showSuccessToast(`Consulta para ${dayjs(selectedStartDateTime).locale('pt').format('dddd, DD/MM/YYYY [às] HH:mm')} atualizada com sucesso.`, 4000);
            handleFormSubmit(moment(selectedDate).format('MMMM').toUpperCase(), moment(selectedDate).year());
            handleClose();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const fetchAppointmentDetails = async () => {
        try {
            const appointmentDetails = await getAppointmentDetails(user.access_token, selectedAppointment.id);

            setSelectedDate(selectedAppointment.start);
            setSelectedStatus(appointmentDetails.data.appointment_status);
            setDentistDetails(appointmentDetails.data.dentist_details);
            setSelectedPatient(appointmentDetails.data.patient_details);
            setDescription(appointmentDetails.data.description);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleDentistChange = (event) => {
        const selectedDentistId = event.target.value;
        let dentist = dentistsDropdownOptions.find(option => option.value === selectedDentistId);
        if (dentist == null) {
            dentist = {
                label: 'Não selecionado',
                value: null
            };
        }
        setSelectedDentist(dentist);
    };

    const fetchDentistsDropdownOptions = async () => {
        try {
            const response = await getUsers(user.access_token, 0, 10000, 'DENTIST');
            const options = response.data.content.map((user) => ({
                value: user.user_external_id,
                label: `${user.first_name} ${user.last_name}`,
            }));
            setDentistsDropdownOptions(options);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const fetchSearchResults = async (query) => {
        try {
            const response = await getAutocompleteSearchPatients(user.access_token, query, 0, 10);
            const content = response.data.content || [];
            setSearchResults(content);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleSearchChange = (event, query) => {
        setSearchText(query);
        fetchSearchResults(query);
    };

    const handleConfirmationDialog = () => {

        var formError = false;

        if (!selectedStartDateTime || dayjs(selectedStartDateTime).isSame(dayjs(selectedStartDateTime).startOf('day'))) {
            setStartTimeError('Horário de início é obrigatório');
            formError = true;
        } else {
            setStartTimeError('');
        }

        if (!selectedEndDateTime || dayjs(selectedEndDateTime).isSame(dayjs(selectedEndDateTime).startOf('day'))) {
            setEndTimeError('Horário de fim é obrigatório');
            formError = true;
        } else if (dayjs(selectedEndDateTime).isBefore(dayjs(selectedStartDateTime))) {
            setEndTimeError('Horário de fim não pode ser antes do horário de início');
            formError = true;
        } else {
            setEndTimeError('');
        }

        if (!selectedDentist) {
            setDentistError('Médico dentista é obrigatório');
            formError = true;
        } else {
            setDentistError('');
        }

        if (!selectedPatient) {
            setPatientError('Paciente é obrigatório');
            formError = true;
        } else {
            setPatientError('');
        }

        if (!formError) {
            setConfirmationDialogOpen(true);
        }
    }

    const handlePatientCreated = (createdPatient, patientExternalId) => {
        createdPatient.patient_external_id = patientExternalId;
        setSelectedPatient(createdPatient);
    };

    const handleStartTimeChange = (newValue) => {
        setSelectedStartDateTime(newValue);
    };

    const handleEndTimeChange = (newValue) => {
        setSelectedEndDateTime(newValue);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            style={{ width: '100%' }}
            maxWidth={false}
            PaperProps={{
                style: {
                    width: '650px',
                    margin: 'auto',
                },
            }}
        >
            <DialogContent>
                {loading ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '60vh' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <Formik
                        onSubmit={handleConfirmationDialog}
                        initialValues={[]}
                    >
                        <Form id="updateEventForm" className="default-form">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="personal-data" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                                        <IconButton color="inherit" aria-label="menu">
                                            <Event />
                                        </IconButton>
                                        Consulta de {dayjs(selectedDate).locale('pt').format('dddd, LL')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <br />

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <FormControl style={{ width: '100%' }} margin="normal">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt"
                                            style={{ width: '100%' }}
                                            margin="normal"
                                        >
                                            <DatePicker
                                                label="Data da consulta"
                                                format="DD/MM/YYYY"
                                                defaultValue={dayjs(selectedDate)}
                                                onChange={date => handleDateTimePickerChange(date)}
                                                disabled={readOnly}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <FormControl style={{ width: '100%' }} margin="normal">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt"
                                            style={{ width: '100%' }}
                                            margin="normal"
                                        >
                                            <TimePicker
                                                label="Inicio"
                                                ampm={false}
                                                disabled={readOnly}
                                                value={dayjs(selectedStartDateTime)}
                                                onChange={(newValue) => handleStartTimeChange(newValue)}
                                            />
                                        </LocalizationProvider>
                                        {startTimeError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{startTimeError}</div>}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <FormControl style={{ width: '100%' }} margin="normal">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt"
                                            style={{ width: '100%' }}
                                            margin="normal"
                                        >
                                            <TimePicker
                                                label="Fim"
                                                ampm={false}
                                                disabled={readOnly}
                                                value={dayjs(selectedEndDateTime)}
                                                onChange={(newValue) => handleEndTimeChange(newValue)}
                                            />
                                        </LocalizationProvider>
                                        {endTimeError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{endTimeError}</div>}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={7}>
                                    <FormControl variant="outlined" style={{ width: '100%' }} margin="normal">
                                        <InputLabel id="status-label">Status da consulta</InputLabel>
                                        <Field
                                            name="status"
                                            as={Select}
                                            labelId="status-label"
                                            defaultValue={selectedStatus}
                                            value={selectedStatus}
                                            onChange={(event) => setSelectedStatus(event.target.value)}
                                            label="Status da consulta"
                                            disabled={readOnly}
                                        >
                                            <MenuItem value="NOT_CONFIRMED">
                                                <ListItem disablePadding>
                                                    <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                                        <Warning style={{ color: 'yellow' }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Criado / Não confirmado" style={{ margin: '0' }} />
                                                </ListItem>
                                            </MenuItem>
                                            <MenuItem value="CONFIRMED">
                                                <ListItem disablePadding>
                                                    <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                                        <Check style={{ color: 'green' }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Confirmado" style={{ margin: '0' }} />
                                                </ListItem>
                                            </MenuItem>
                                            <MenuItem value="CANCELED_RESCHEDULED">
                                                <ListItem disablePadding>
                                                    <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                                        <EventRepeat style={{ color: 'orange' }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Cancelado / Remarcado" style={{ margin: '0' }} />
                                                </ListItem>
                                            </MenuItem>
                                            <MenuItem value="CANCELED_NOT_RESCHEDULED">
                                                <ListItem disablePadding>
                                                    <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                                        <Cancel style={{ color: 'red' }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Cancelado / Não remarcado" style={{ margin: '0' }} />
                                                </ListItem>
                                            </MenuItem>
                                        </Field>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={5}>
                                    <FormControl style={{ width: '100%' }} margin="normal">
                                        <InputLabel id="select-dentist">Médico dentista</InputLabel>
                                        <Field
                                            as={Select}
                                            name="dentist"
                                            labelId="doctor-label"
                                            value={selectedDentist.value}
                                            onChange={handleDentistChange}
                                            label="Médico dentista"
                                            disabled={readOnly}
                                        >
                                            {dentistsDropdownOptions.map((option) => (
                                                <MenuItem
                                                    name="dentist"
                                                    key={option.value}
                                                    value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                        {dentistError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{dentistError}</div>}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={7}>
                                    <FormControl style={{ width: '100%' }} margin="normal">
                                        <Autocomplete
                                            value={selectedPatient}
                                            onChange={(event, query) => setSelectedPatient(query)}
                                            inputValue={searchText}
                                            onInputChange={handleSearchChange}
                                            options={searchResults}
                                            getOptionLabel={(option) => option ? (option.taxpayer_number ? `${option.taxpayer_number} - ${option.name}` : `${option.name}`) : ''}
                                            noOptionsText="Não encontrado"
                                            disabled={readOnly}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Paciente"
                                                    variant="outlined"
                                                    placeholder={"340785409 - Joana Ferreira"}
                                                />
                                            )}
                                            PaperComponent={({ children }) => {
                                                return (
                                                    <Paper>
                                                        {children}
                                                        <Button
                                                            color="primary"
                                                            style={{ width: '100%' }}
                                                            sx={{ justifyContent: "flex-start", pl: 2 }}
                                                            onMouseDown={() => {
                                                                openCreatePatientDialog()
                                                            }}
                                                        >
                                                            Registrar novo paciente
                                                        </Button>
                                                    </Paper>
                                                );
                                            }}
                                        />
                                        {patientError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{patientError}</div>}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Textarea
                                        name="description"
                                        style={{ width: '100%' }}
                                        margin="normal"
                                        slots={{ textarea: InnerTextarea }}
                                        slotProps={{ textarea: { placeholder: 'Paciente precisa confirmar o agendamento ...' } }}
                                        sx={{ borderRadius: '6px' }}
                                        value={description}
                                        onChange={(event) => setDescription(event.target.value)}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            </Grid>

                            <div style={{ margin: '20px 0' }}></div>

                            {!readOnly ? (
                                <>
                                    <Button
                                        onClick={handleClose}
                                        variant="contained"
                                        style={{ backgroundColor: 'white', color: 'black', marginRight: '20px' }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button type="submit"
                                        variant="contained"
                                        style={{ backgroundColor: 'darkgreen', color: 'white', marginRight: '20px' }}
                                    >
                                        Atualizar Consulta
                                    </Button>
                                </>
                            ) : (

                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                    style={{ backgroundColor: 'white', color: 'black', marginRight: '20px' }}
                                >
                                    Voltar
                                </Button>
                            )}

                            {allowedEditEventUserType && (
                                <Button
                                    onClick={toggleEditMode}
                                    variant="contained"
                                    style={{ backgroundColor: 'darkgreen', color: 'white' }}
                                >
                                    {readOnly ? <Typography style={{ fontSize: '0.875rem' }}>
                                        Editar consulta
                                    </Typography > :
                                        <Tooltip title="Fechar modo edição"><Lock /></Tooltip>
                                    }
                                </Button>
                            )}

                            {allowedEditEventUserType && (
                                <IconButton onClick={openDeleteDialog}>
                                    <Tooltip title="Remover consulta"><Delete style={{ color: 'red' }} /></Tooltip>
                                </IconButton>
                            )}
                        </Form>
                    </Formik>
                )}
            </DialogContent>
            <QuickCreatePatientDialog
                open={createPatientDialogOpen}
                handleClose={() => setCreatePatientDialogOpen(false)}
                onPatientCreated={handlePatientCreated}
            />
            <CreateEventConfirmationDialog
                open={confirmationDialogOpen}
                onClose={closeConfirmationDialog}
                selectedDate={selectedDate}
                selectedStartDateTime={selectedStartDateTime}
                selectedEndDateTime={selectedEndDateTime}
                selectedDentist={selectedDentist}
                selectedPatient={selectedPatient}
                description={description}
                status={selectedStatus}
                handleConfirmCreation={handleUpdateEvent}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
                onConfirm={handleConfirmDelete}
                title="Remover consulta"
                contentText={`Tem certeza de que deseja excluir essa consulta?`}
            />
        </Dialog>
    );
};

export default ViewEventDialog;
