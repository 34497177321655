const userRegistration = {
    personal_details: {
        username: 'string',
        password: 'string',
        first_name: 'string',
        last_name: 'string',
        user_type: 'CLINIC_OWNER',
        contacts: [
            {
                contact_type: 'PHONE_NUMBER',
                contact_value: 'string',
            }
        ]
    },
    addresses: [
        {
            street: 'string',
            complement: 'string',
            district: 'string',
            city: 'string',
            zip_code: 'string'
        }
    ]
};

export const mapFormValuesToUserRegistration = (formValues, signUpId, role) => {
    return {
        signup_id : signUpId,
        personal_details: {
            username: formValues.username,
            password: formValues.password,
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            user_type: role,
            contacts: [
                {
                    contact_type: 'PHONE_NUMBER',
                    contact_value: formValues.phoneNumber,
                },
                {
                    contact_type: 'EMAIL',
                    contact_value: formValues.email,
                }                
            ]
        },
        addresses: [
            {
                street: formValues.street,
                complement: formValues.complement,
                district: formValues.locale,
                city: formValues.city,
                zip_code: formValues.zipCode,
            }
        ]
    };
};

export const mapFormValuesToUserRegistrationV2 = (formValues, username, password) => {
    return {
        personal_details: {
            username: username,
            password: password,
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            user_type: formValues.role,
            contacts: [
                {
                    contact_type: 'PHONE_NUMBER',
                    contact_value: formValues.phoneNumber,
                },
                {
                    contact_type: 'EMAIL',
                    contact_value: formValues.email,
                }                
            ]
        },
        addresses: [
            {
                street: formValues.street,
                complement: formValues.complement,
                district: formValues.locale,
                city: formValues.city,
                zip_code: formValues.zipCode,
            }
        ]
    };
};



export default userRegistration;