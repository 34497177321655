import React, { useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { CircularProgress, Dialog, DialogContent, Grid, IconButton, TextField, Typography, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/UserContext';
import { getPatientRecordDetails } from '../../../services/PatientRecordService';
import { handleError } from '../../../helpers/ErrorHandling';
import AssignmentIcon from '@mui/icons-material/Assignment';

const ViewEditPatientRecordDialog = ({ open, handleClose, patientRecord }) => {
    const { user, clearUser } = useUser();
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState(null);
    const [observation, setObservation] = useState('-');
    const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const { data } = await getPatientRecordDetails(user.access_token, patientRecord.id);
            setObservation(data.observation);
            setImage(data.patient_record_image);
        } catch (error) {
            handleError(error, clearUser, navigate);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [open]);

    const handleImageClick = () => {
        setIsImageDialogOpen(true);
    };

    const handleCloseImageDialog = () => {
        setIsImageDialogOpen(false);
    };

    return (
        <div>
            {loading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '60vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <Dialog
                        open={open}
                        style={{ width: '100%' }}
                        maxWidth={false}
                        PaperProps={{
                            style: {
                                width: '550px',
                                margin: 'auto'
                            },
                        }}
                    >
                        <Typography variant="body1" style={{ marginBottom: 8, color: 'green' }}>
                            <IconButton color="inherit" aria-label="menu">
                                <AssignmentIcon />
                            </IconButton>
                            <strong>N.º página: </strong> {patientRecord.pageNumber}
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Observações (opcional)"
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                    fullWidth
                                    value={observation}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            {image && (
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Tooltip title="Clique para ampliar a imagem" arrow>
                                        <img
                                            src={`data:image/jpeg;base64,${image}`}
                                            alt="Patient record"
                                            style={{ maxWidth: '100%', maxHeight: '300px', cursor: 'pointer' }}
                                            onClick={handleImageClick}
                                        />
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                        <DialogActions sx={{ justifyContent: 'flex-start' }}>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                style={{ backgroundColor: 'white', color: 'black' }}
                            >
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {image && (
                        <Dialog
                            open={isImageDialogOpen}
                            onClose={handleCloseImageDialog}
                            maxWidth="md"
                            fullWidth
                        >
                            <DialogContent>
                                <img
                                    src={`data:image/jpeg;base64,${image}`}
                                    alt="Patient record - full size"
                                    style={{ width: '100%' }}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                </>
            )}
        </div>
    );
};

export default ViewEditPatientRecordDialog;
