// Home.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Tooltip,
  Box,
  Paper,
} from '@mui/material';
import { login } from '../../services/SigninService.js';
import '../../styles/styles.css';
import sorrisoSoberanoLogo from '../../images/sorriso_soberano_logo_01.png';
import { showErrorToast, showSuccessToast } from '../../helpers/ToastHelper.js';
import { mapErrorCodeToMessage } from '../../helpers/ErrorCodeMessageHelper.js';
import { useNavigate } from "react-router-dom";
import { useUser } from '../../contexts/UserContext.js';
import { SCHEDULE_PATH } from '../../Constants.js';
import HomeUpdatePasswordDialog from './HomeUpdatePasswordDialog.js';
import backgroundImage from '../../images/default_background_04.jpg';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const Home = () => {
  document.title = 'Login - Sorriso Soberano';

  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [firstUserLogin, setFirstUserLogin] = useState(true);
  const [oldPassword, setOldPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, updateUser } = useUser();

  useEffect(() => {
    if (user && !firstUserLogin) {
      navigate(SCHEDULE_PATH);
    }
  }, [user, navigate]);

  const handleOpenResetPasswordModal = () => {
    setResetPasswordModalOpen(true);
  };

  const handleCloseResetPasswordModal = () => {
    setResetPasswordModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get('email');
    const password = formData.get('password');

    setLoading(true);
    try {
      const response = await login({ username, password });
      updateUser(response.data);

      if (response.data.is_first_login) {
        setFirstUserLogin(true);
        setOldPassword(password);
        handleOpenResetPasswordModal();
      } else {
        setOldPassword(null);
        setFirstUserLogin(false);
        navigate(SCHEDULE_PATH);
        showSuccessToast('Login realizado com sucesso', 1500);
      }
    } catch (error) {
      const errorMessage = mapErrorCodeToMessage(error);
      showErrorToast(errorMessage, 5000);
    }
    setLoading(false);
  };

  return (
    <Box
      className="login-container"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container maxWidth="xs">
        <Paper elevation={6} sx={{ padding: 4, borderRadius: 3, textAlign: 'center', bgcolor: 'rgba(255, 255, 255, 0.9)' }}>
          <img src={sorrisoSoberanoLogo} alt="App Logo" style={{ width: '80%', margin: '0 auto 20px' }} />
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            style={{
              color: 'darkgreen',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 500,
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
              letterSpacing: '0.5px',
            }}
          >
            Bem-vindo! <br /> Inicie a sessão na sua conta
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              name="email"
              label="Utilizador"
              variant="outlined"
              fullWidth
              margin="dense"
              required
            />
            <TextField
              name="password"
              type="password"
              label="Senha"
              variant="outlined"
              fullWidth
              margin="dense"
              required
            />
            <Button
              variant="contained"
              disabled={loading}
              style={{
                backgroundColor: 'darkgreen', color: 'white', height: '50px', fontSize: '1rem',
                letterSpacing: '0.5px',
                mt: 3,
                boxShadow: 3,
              }}
              type="submit"
              fullWidth
            >
              Acessar
            </Button>
          </form>

          <Tooltip title="Para recuperar sua senha, por favor peça ao administrador para realizar o 'reset' de sua senha">
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, cursor: 'pointer' }}>
              <WarningAmberIcon color="warning" sx={{ mr: 1 }} />
              <Typography variant="body2" color="textSecondary">
                Esqueci minha senha
              </Typography>
            </Box>
          </Tooltip>
        </Paper>

        <HomeUpdatePasswordDialog
          oldPassword={oldPassword}
          open={resetPasswordModalOpen}
          onClose={handleCloseResetPasswordModal}
          user={user}
        />
      </Container>
    </Box>
  );
};

export default Home;
