const updatePatientDetails = {
    invoicingId: 'string',
    name: 'string',
    birthdate: 'string',
    age: 'number',
    email: 'string',
    phoneNumber: 'string',
    profession: 'string',
    citizenshipCard: 'string',
    taxpayerNumber: 'string',
    utenteNumber: 'string',
    medicalInsurance: 'string',
    street: 'string',
    locality: 'string',
    zipCode: 'string'
};

export const mapFormValuesToPatientDetails = (formValues, birthdate, healthInsuranceId) => {
    return {
        invoicing_id: formValues.invoicingId,
        name: formValues.name,
        birthdate: birthdate,
        age: formValues.age,
        email: formValues.email,
        phone_number: formValues.phoneNumber,
        profession: formValues.profession,
        citizenship_card: formValues.citizenshipCard,
        taxpayer_number: formValues.taxpayerNumber,
        utente_number: formValues.utenteNumber,
        health_insurance_id: healthInsuranceId,
        address: {
            street: formValues.street,
            locality: formValues.locality,
            zip_code: formValues.zipCode,
        }
    }
};

export default updatePatientDetails;