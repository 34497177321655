import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/patient-service/api/v1`;

const api = axios.create({
    baseURL: API_BASE_URL
});

export const getHealthInsuranceList = async (token) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.get('/health-insurance', {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteHealthInsurance = async (token, healthInsuranceId) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.delete(`/health-insurance/${healthInsuranceId}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createHealthInsurance = async (token, healthInsuranceResgistration) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await api.post('/health-insurance', healthInsuranceResgistration, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

