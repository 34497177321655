import { mapErrorCodeToMessage } from "./ErrorCodeMessageHelper";
import { showErrorToast, showWarnToast } from "./ToastHelper";

export const handleError = (error, clearUser, navigate) => {
    const errorMessage = mapErrorCodeToMessage(error);

    var internalCode = ''
    if (error.response && error.response.data && error.response.data.internal_code) {
        internalCode = error.response.data.internal_code;
    }

    if (internalCode === 'DENTAL_GATEWAY_003') {
        clearUser();
        showWarnToast(errorMessage, 5000);
        navigate('/');
    } else {
        showErrorToast(errorMessage, 5000);
    }
};
