import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { Alert } from '@mui/material';

const FormError = ({ name }) => (
  <ErrorMessage name={name}>
    {(msg) => (
      <Alert severity="error">
        {msg}
      </Alert>
    )}
  </ErrorMessage>
);

export const FormErrorV2 = ({ name }) => (
  <Field name={name}>
    {({ form }) => {
      const error = form.errors[name];
      const touched = form.touched[name];
      return touched && error ? (
        <span style={{ color: 'red', fontSize: '0.75rem' }}>{error}</span>
      ) : null;
    }}
  </Field>
);


export default FormError;
