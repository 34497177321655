// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SignUp from './components/SignUp';
import DentalDashboard from './components/dashboard/DentalDashboard';
import { UserProvider } from './contexts/UserContext';
import SettingsProfile from './components/settings/SettingsProfile';
import PatientsManagement from './components/patient-management/PatientsManagement';
import UsersManagement from './components/users-management/UsersManagement';
import {
  HOMEPAGE_PATH,
  DASHBOARD_PATH,
  PATIENT_MANAGEMENT_PATH,
  SCHEDULE_PATH,
  USERS_MANAGEMENT_PATH,
  SETTINGS_PROFILE_PATH,
  SIGN_UP_PATH,
  AUDIT_PATH,
  PERFORMANCE_ANALYSIS_PATH
} from './Constants';
import CalendarPage from './components/calendar/Calendar';
import Home from './components/home/Home';
import LoadingSpinner from './components/LoadingSpinner';
import { LoadingProvider } from './contexts/LoadingContext';
import Audit from './components/audit/Audit';
import PerformanceAnalysis from './components/performance-analysis/PerformanceAnalysis';
import NotFoundPage from './components/NotFoundPage';

const App = () => {

  return (
    <UserProvider>
      <LoadingProvider>
        <Router>
          <div>
            <ToastContainer />
            <LoadingSpinner />
            <Routes>
              <Route path={HOMEPAGE_PATH} element={<Home />} />
              <Route path={SIGN_UP_PATH} element={<SignUp />} />
              <Route path={DASHBOARD_PATH} element={<DentalDashboard />} />
              <Route path={SCHEDULE_PATH} element={<CalendarPage />} />
              <Route path={PATIENT_MANAGEMENT_PATH} element={<PatientsManagement />} />
              <Route path={USERS_MANAGEMENT_PATH} element={<UsersManagement />} />
              <Route path={SETTINGS_PROFILE_PATH} element={<SettingsProfile />} />
              <Route path={AUDIT_PATH} element={<Audit />} />
              <Route path={PERFORMANCE_ANALYSIS_PATH} element={<PerformanceAnalysis />} />
              <Route path="*" element={<NotFoundPage />} />
              {/* <Route path="/anamnesis" element={<Anamnesis />} /> */}
            </Routes>
          </div>
        </Router>
      </LoadingProvider>
    </UserProvider>
  );
};

export default App;