import React, { useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Formik, Form, Field } from 'formik';
import { CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FormErrorV2 } from '../../helpers/FormError';
import { showSuccessToast } from '../../helpers/ToastHelper';
import { patientsValidationSchema } from '../../helpers/ValidationSchemaHelper';
import { mapFormValuesToPatientDetails } from '../../data/updatePatientDetails';
import { getPatientDetails, updatePatientDetails } from '../../services/PatientService';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../helpers/ErrorHandling';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { createHealthInsurance, deleteHealthInsurance, getHealthInsuranceList } from '../../services/HealthInsuranceService';
import { Delete } from '@mui/icons-material';

const ViewEditPatient = ({ open, onClose, patient }) => {

    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const { user, clearUser } = useUser();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(null);
    const [readOnly, setReadOnly] = useState(true);
    const [allowedUpdatePatient, setllowedUpdatePatient] = useState(false);
    const [healthInsuranceDropdownOptions, setHealthInsuranceDropdownOptions] = useState([]);
    const [selectedHealthInsurance, setSelectedHealthInsurance] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [isAddingNewInsurance, setIsAddingNewInsurance] = useState(false);
    const [birthdateError, setBirthdateError] = useState('');

    const initialValues = {
        invoicingId: userData?.invoicing_id || '',
        name: userData?.name || '',
        email: userData?.email || '',
        phoneNumber: userData?.phone_number || '',
        profession: userData?.profession || '',
        citizenshipCard: userData?.citizenship_card || '',
        taxpayerNumber: userData?.taxpayer_number || '',
        utenteNumber: userData?.utente_number || '',
        healthInsurance: userData?.health_insurance_id || '',
        street: userData?.address?.street || '',
        locality: userData?.address?.locality || '',
        zipCode: userData?.address?.zip_code || ''
    };

    const fetchData = async () => {
        try {
            const response = await getPatientDetails(user.access_token, patient.id);
            setUserData(response.data);
            if (response.data.birthdate) {
                setSelectedDate(dayjs(response.data.birthdate).toDate());
            }
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    useEffect(() => {
        if (open && !initialized) {
            fetchHealthInsuranceDropdownOptions();
            fetchData();
            setInitialized(true);
        }

    }, [open, loading, healthInsuranceDropdownOptions, selectedHealthInsurance]);

    useEffect(() => {

        if (loading && healthInsuranceDropdownOptions.length > 0) {
            const selectedHealthInsuranceId = initialValues?.healthInsurance || '';
            let healthInsurance = healthInsuranceDropdownOptions.find(option => option.value === selectedHealthInsuranceId);
            setSelectedHealthInsurance(healthInsurance);
        }

        if (user.user_type_name !== 'DENTIST') {
            setReadOnly(false);
            setllowedUpdatePatient(true);
        }

        if (selectedHealthInsurance != null) {
            setLoading(false);
        }
    });

    const fetchHealthInsuranceDropdownOptions = async () => {
        try {
            const response = await getHealthInsuranceList(user.access_token);
            const options = response.data.map((healthInsurance) => ({
                value: healthInsurance.health_insurance_id,
                label: healthInsurance.name,
                isDefault: healthInsurance.is_default
            }));
            setHealthInsuranceDropdownOptions(options);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleUpdatePatient = async (values, { setSubmitting }) => {

        try {
            await patientsValidationSchema.validate(values, { abortEarly: false });

            var formattedDate;

            if (selectedDate) {
                setBirthdateError('')
                formattedDate = dayjs(selectedDate).locale('pt').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            } else {
                setBirthdateError('Data nascimento é obrigatório');
                return;
            }

            const mappedPatientUpdate = mapFormValuesToPatientDetails(values, formattedDate || null, selectedHealthInsurance.value);
            await updatePatientDetails(user.access_token, patient.id, mappedPatientUpdate);
            showSuccessToast(`Paciente ${mappedPatientUpdate.name} atualizado com sucesso.`, 1500);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleChange = (date) => {
        setSelectedDate(date);
    };

    const handleHealthInsuranceChange = (event) => {

        const selectedHealthInsuranceId = event.target.value;

        if (selectedHealthInsuranceId === 'add-new') {
            setIsAddingNewInsurance(true);
            setSelectedHealthInsurance('');
        } else {
            const selectedOption = healthInsuranceDropdownOptions.find(option => option.value === selectedHealthInsuranceId);
            setSelectedHealthInsurance(selectedOption || { label: 'Não selecionado', value: null });
            setIsAddingNewInsurance(false);
        }
    };

    const handleAddNewHealthInsurance = async (event) => {
        if (event.key === 'Enter' && event.target.value.trim()) {
            event.preventDefault();
            try {
                const newHealthInsuranceName = event.target.value;
                const response = await createHealthInsurance(user.access_token, { name: newHealthInsuranceName });
                const newOption = { value: response.data.health_insurance_id, label: response.data.name, isDefault: response.is_default };
                setHealthInsuranceDropdownOptions([...healthInsuranceDropdownOptions, newOption]);
                setSelectedHealthInsurance(newOption);
                setIsAddingNewInsurance(false);
                showSuccessToast(`Convenção/Seguro "${newHealthInsuranceName}" adicionado com sucesso.`);
            } catch (error) {
                handleError(error, clearUser, navigate);
            }
        }
    };


    const handleDeleteHealthInsurance = async (id) => {
        try {
            await deleteHealthInsurance(user.access_token, id);
            showSuccessToast('Convenção/Seguro deletada com sucesso.');
            fetchHealthInsuranceDropdownOptions();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={patientsValidationSchema}
            onSubmit={handleUpdatePatient}
        >
            {() => (

                <div>
                    {loading ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '60vh' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <Form id="patientForm" className="default-form">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        type="text"
                                        name="name"
                                        label="* Nome"
                                        as={TextField}
                                        vvariant="outlined"
                                        fullWidth
                                        margin="normal"
                                        disabled={readOnly}
                                        helperText={<FormErrorV2 name="name" />}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3.5}>
                                    <FormControl style={{ width: '100%' }} margin="normal">
                                        <LocalizationProvider
                                            name='birthdate'
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt"
                                            style={{ width: '100%' }}
                                            margin="normal"
                                        >
                                            <DatePicker
                                                name='birthdate'
                                                label="* Data nascimento"
                                                format="DD/MM/YYYY"
                                                value={selectedDate ? dayjs(selectedDate) : null}
                                                onChange={handleChange}
                                                disabled={readOnly}
                                            />
                                            {birthdateError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{birthdateError}</div>}
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={2.5}>
                                    <Field
                                        type="text"
                                        name="invoicingId"
                                        label="ID faturamento"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="invoicingId" />}
                                        disabled={readOnly}
                                    />
                                </Grid>

                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <Field
                                        type="text"
                                        name="phoneNumber"
                                        label="* Telemóvel"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="phoneNumber" />}
                                        disabled={readOnly}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={5}>
                                    <Field
                                        type="text"
                                        name="email"
                                        label="* E-mail"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="email" />}
                                        disabled={readOnly}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field
                                        type="text"
                                        name="profession"
                                        label="Profissão"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="profession" />}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={4}>
                                    <Field
                                        type="text"
                                        name="citizenshipCard"
                                        label="Cartão cidadão"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="citizenshipCard" />}
                                        disabled={readOnly}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field
                                        type="text"
                                        name="taxpayerNumber"
                                        label="Contribuinte (NIF)"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="taxpayerNumber" />}
                                        disabled={readOnly}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field
                                        type="text"
                                        name="utenteNumber"
                                        label="Número utente"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="utenteNumber" />}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={5}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="select-health-insurance">Convenção/Seguro</InputLabel>
                                        <Field
                                            as={Select}
                                            name="medicalInsurance"
                                            labelId="health-insurance-label"
                                            value={selectedHealthInsurance ? selectedHealthInsurance.value : ''}
                                            onChange={handleHealthInsuranceChange}
                                            label="Convenção/Seguro"
                                            displayEmpty
                                            disabled={readOnly}
                                        >
                                            {healthInsuranceDropdownOptions.map((option) => (

                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                    {!option.isDefault && (
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={() => handleDeleteHealthInsurance(option.value)}
                                                            size="small"
                                                            sx={{ marginLeft: 'auto' }}
                                                        >
                                                            <Delete fontSize="small" />
                                                        </IconButton>
                                                    )}
                                                </MenuItem>
                                            ))}
                                            <MenuItem value="add-new" sx={{ fontStyle: 'italic', color: 'green' }}>
                                                + Criar novo
                                            </MenuItem>
                                        </Field>
                                        {isAddingNewInsurance && (
                                            <TextField
                                                placeholder="Digite o novo nome"
                                                fullWidth
                                                margin="normal"
                                                onKeyDown={handleAddNewHealthInsurance}
                                                autoFocus
                                            />
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={9}>
                                    <Field
                                        type="text"
                                        name="street"
                                        label="Morada"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="street" />}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={6}>
                                    <Field
                                        type="text"
                                        name="locality"
                                        label="Localidade"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="locality" />}
                                        disabled={readOnly}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Field
                                        type="text"
                                        name="zipCode"
                                        label="Código postal"
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        helperText={<FormErrorV2 name="zipCode" />}
                                        disabled={readOnly}
                                    />
                                </Grid>

                            </Grid>
                            <DialogActions sx={{ justifyContent: 'flex-start' }}>
                                <Button onClick={onClose}
                                    variant="contained"
                                    style={{ backgroundColor: 'white', color: 'black' }}
                                >
                                    Cancelar
                                </Button>
                                <div style={{ marginLeft: '20px' }}></div>
                                {allowedUpdatePatient && (
                                    <Button type="submit"
                                        variant="contained"
                                        style={{ backgroundColor: 'darkgreen', color: 'white' }}
                                    >
                                        Salvar
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    )}
                </div >
            )}
        </Formik>
    );
};

export default ViewEditPatient;