import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DentalDashboard from '../dashboard/DentalDashboard.js';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useUser } from '../../contexts/UserContext.js';
import { mapUserTypeToText } from '../../helpers/EnumHelper.js';
import { showSuccessToast } from '../../helpers/ToastHelper.js';
import ConfirmationDialog from '../../helpers/ConfirmationDialog.js';
import { Avatar, Button, Container, Divider, Grid, InputAdornment, TablePagination, TextField, Tooltip, Typography } from '@mui/material';
import { deletePatient, getAutocompleteSearchPatients } from '../../services/PatientService.js';
import MainPatientDialog from './MainPatientDialog.js';
import { Add, Search, Visibility } from '@mui/icons-material';
import AddPatientDialog from './AddPatientDialog.js';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../helpers/ErrorHandling.js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const DeleteIconWrapper = styled(DeleteIcon)`
  cursor: pointer;
  color: red;
`;

const VisibilityIconWrapper = styled(Visibility)`
  cursor: pointer;
  color: grey;
`;

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    let initials = '';
    const nameArray = name.split(' ');

    if (nameArray.length > 1) {
        initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    } else {
        initials = name.substring(0, 2).toUpperCase();
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initials,
    };
}

const PatientsManagement = () => {
    const [rows, setRows] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { user, clearUser } = useUser();
    const token = user?.access_token || null;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [selectedPatient, setselectedPatient] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const [allowedManagePatient, setAllowedManagePatient] = useState(false);

    const fetchData = async (query = '', currentPage = page, currentRowsPerPage = rowsPerPage) => {

        // Set a minimum number of caracteres to fetch data from backend side
        if (query.length != 0 && query.length < 3) {
            return [];
        }

        try {
            const response = await getAutocompleteSearchPatients(token, query, currentPage, currentRowsPerPage);
            const content = response.data.content || [];
            const totalElements = response.data.total_elements || 0;
            const totalPages = Math.ceil(totalElements / currentRowsPerPage);

            setRows(content);
            setTotalPages(totalPages);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or when the search query changes with a delay
        const fetchDataTimeout = setTimeout(() => {
            fetchData(searchQuery || '', page, rowsPerPage);
        }, 300);

        if (user.user_type_name !== 'DENTIST') {
            setAllowedManagePatient(true);
        }

        // Clear the timeout on component unmount or when searchQuery, page, or rowsPerPage changes
        return () => clearTimeout(fetchDataTimeout);
    }, [searchQuery, page, rowsPerPage]);


    const handleChangePage = (event, newPage) => {
        // Update page and fetch data
        setPage(newPage);
        fetchData(searchQuery, newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // Update rowsPerPage and fetch data
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        fetchData(searchQuery, 0, newRowsPerPage);
    };

    const handleDelete = (id, name) => {
        setselectedPatient({
            id,
            name
        });
        setDeleteDialogOpen(true);
    };

    const handleEdit = (id, name, taxpayerNumber) => {
        setselectedPatient({
            id,
            name,
            taxpayerNumber
        });
        setUpdateDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setselectedPatient({});
    };

    const handleCloseUpdateDialog = () => {
        setUpdateDialogOpen(false);
        setselectedPatient({});
    };

    const handleOpenAddDialog = () => {
        setAddDialogOpen(true);
        fetchData(searchQuery || '', page, rowsPerPage);
    };

    const handleCloseAddDialog = () => {
        setAddDialogOpen(false);
        fetchData(searchQuery || '', page, rowsPerPage);
    };

    const handleConfirmDelete = async () => {
        try {
            await deletePatient(token, selectedPatient.id);
            showSuccessToast(
                `Paciente ${selectedPatient.name} deletado com sucesso.`,
                1500
            );
            fetchData(searchQuery);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
        handleCloseDeleteDialog();
    };

    const filteredRows = rows.filter(
        (row) =>
            row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.phone_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.taxpayer_number.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <DentalDashboard>
            <Container maxWidth="lg" sx={{ marginTop: 4 }}>
                <TableContainer sx={{ maxWidth: 1000, margin: '16px auto 0' }}>
                    <Typography
                        variant="h4"
                        noWrap
                        component="div"
                        align="left"
                        sx={{
                            fontFamily: 'Arial, sans-serif',
                            fontWeight: 'bold',
                            color: 'darkgreen',
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                            letterSpacing: '1px',
                            marginBottom: '10px',
                        }}
                    >
                        Gerenciar Pacientes
                    </Typography>
                    <br />
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <TextField
                                label="Procurar paciente"
                                variant="outlined"
                                placeholder="NIF, nome ou telémovel"
                                value={searchQuery}
                                onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                    setPage(0);
                                }}
                                style={{ marginTop: '15px', marginBottom: '30px', padding: '5px', width: '300px' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        {allowedManagePatient && (
                            <Grid item>
                                <Button
                                    align="right"
                                    variant="contained"
                                    style={{ backgroundColor: 'darkgreen', color: 'white', textTransform: 'none' }}
                                    onClick={handleOpenAddDialog}
                                    startIcon={<Add />}
                                >
                                    Novo paciente
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <AddPatientDialog open={addDialogOpen} onClose={handleCloseAddDialog} />
                    <Divider style={{ marginBottom: '30px' }} />
                    <Table sx={{ minWidth: 200 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">NIF</StyledTableCell>
                                <StyledTableCell align="center">Nome do Paciente</StyledTableCell>
                                <StyledTableCell align="center">E-mail</StyledTableCell>
                                <StyledTableCell align="center">Telemóvel</StyledTableCell>
                                <StyledTableCell align="center">Ações</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(0, rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row) => (
                                <StyledTableRow key={row.patient_external_id}>
                                    <StyledTableCell align="center">{row.taxpayer_number ? row.taxpayer_number : '-'}</StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar {...stringAvatar(`${row.name}`)} />
                                            <span style={{ marginLeft: '10px' }}>{row.name}</span>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.email ? row.email : '-'}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        {mapUserTypeToText(row.phone_number)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Tooltip title="Visualizar / Editar dados do paciente">
                                            <VisibilityIconWrapper onClick={() => handleEdit(row.patient_external_id, row.name, row.taxpayer_number)} />
                                        </Tooltip>
                                        {allowedManagePatient && (
                                            <Tooltip title="Remover paciente">
                                                <DeleteIconWrapper
                                                    onClick={() => handleDelete(row.patient_external_id, row.name)}
                                                />
                                            </Tooltip>
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalPages * rowsPerPage}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Rows per page:"
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                    />
                    {updateDialogOpen && (
                        <MainPatientDialog
                            open={updateDialogOpen}
                            onClose={handleCloseUpdateDialog}
                            patient={selectedPatient}
                        />
                    )}
                    <ConfirmationDialog
                        open={deleteDialogOpen}
                        onClose={handleCloseDeleteDialog}
                        onConfirm={handleConfirmDelete}
                        title="Remover paciente"
                        contentText={`Tem certeza de que deseja excluir o paciente ${selectedPatient.name}?`}
                    />
                </TableContainer>
                <br />
            </Container>
        </DentalDashboard>
    );
};

export default PatientsManagement;
