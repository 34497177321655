import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { ChromePicker } from 'react-color';
import { updateUserColor } from '../../services/UserService';
import { showSuccessToast } from '../../helpers/ToastHelper';
import { handleError } from '../../helpers/ErrorHandling';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

const ColorPickerDialog = ({ userInfo, open, onClose }) => {

    const { user, clearUser } = useUser();
    const token = user.access_token;
    const [color, setColor] = useState(userInfo.color);
    const navigate = useNavigate();

    useEffect(() => {
        setColor(userInfo.color);
    }, [userInfo.color]);

    const handleChangeComplete = (newColor) => {
        setColor(newColor.hex);
    };

    const handleSave = async () => {
        try {
            await updateUserColor(token, userInfo.id, { "user_color": color });
            showSuccessToast(`Cor do utilizador ${userInfo.firstName} ${userInfo.lastName} atualizada com sucesso.`, 2500);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialogTitle-root': {
                    color: 'darkgreen',
                },
                '& .MuiDialogActions-root': {
                    justifyContent: 'center',
                },
                '& .MuiDialogContentText-root': {
                    color: 'black',
                },
            }}
        >
            <DialogTitle><strong>Selectione a cor desejada</strong></DialogTitle>
            <DialogContent>
                Utilizadores: {userInfo.firstName} {userInfo.lastName}
                <ChromePicker color={color} onChangeComplete={handleChangeComplete} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSave} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ColorPickerDialog;
