import React, { useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Formik, Form, Field } from 'formik';
import { Autocomplete, Dialog, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, TextareaAutosize, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/UserContext';
import { Textarea } from '@mui/joy';
import { styled } from '@mui/joy/styles';
import { handleError } from '../../../helpers/ErrorHandling';
import { createPatientRecord } from '../../../services/PatientRecordService';
import { showSuccessToast } from '../../../helpers/ToastHelper';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { getUsers } from '../../../services/UserService';
import { useDropzone } from 'react-dropzone';

const initialValues = {
    dentists: '',
    patientRecordPage: '',
    selectedImage: '',
    observation: ''
};

const StyledTextarea = styled(TextareaAutosize)({
    resize: 'none',
    border: 'none', // remove the native textarea border
    minWidth: 0, // remove the native textarea width
    outline: 0, // remove the native textarea outline
    padding: 0, // remove the native textarea padding
    paddingBlockStart: '1em',
    paddingInlineEnd: `var(--Textarea-paddingInline)`,
    flex: 'auto',
    alignSelf: 'stretch',
    color: 'inherit',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    '&::placeholder': {
        opacity: 0,
        transition: '0.1s ease-out',
    },
    '&:focus::placeholder': {
        opacity: 1,
    },
    // specific to TextareaAutosize, cannot use '&:focus ~ label'
    '&:focus + textarea + label, &:not(:placeholder-shown) + textarea + label': {
        top: '0.5rem',
        fontSize: '0.75rem',
    },
    '&:focus + textarea + label': {
        color: 'var(--Textarea-focusedHighlight)',
    },
});

const StyledLabel = styled('label')(({ theme }) => ({
    position: 'absolute',
    lineHeight: 1,
    top: 'calc((var(--Textarea-minHeight) - 1em) / 2)',
    color: theme.vars.palette.text.tertiary,
    fontWeight: theme.vars.fontWeight.md,
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

const InnerTextarea = React.forwardRef(function InnerTextarea(props, ref) {
    const id = React.useId();
    return (
        <React.Fragment>
            <StyledTextarea minRows={2} {...props} ref={ref} id={id} />
            <StyledLabel htmlFor={id}>Observações (opcional)</StyledLabel>
        </React.Fragment>
    );
});

const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Remove prefix
            resolve(base64String); // Return only the Base64 part
        };
        reader.onerror = error => reject(error);
    });
};

const CreatePatientRecordDialog = ({ open, handleClose, patientId }) => {

    const [initialized, setInitialized] = useState(false);
    const { user, clearUser } = useUser();
    const [selectedDentists, setSelectedDentists] = useState([]);
    const [selectedPatientRecordPage, setSelectedPatientRecordPage] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [dentistsDropdownOptions, setDentistsDropdownOptions] = useState([]);
    const [observation, setObservation] = useState(null);
    const [dentistError, setDentistError] = useState('');
    const [patientRecordPageError, setPatientRecordPageError] = useState('');
    const [imageError, setImageError] = useState('');
    const navigate = useNavigate();

    const {
        acceptedFiles,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': []
        },
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                try {
                    const base64Image = await convertToBase64(acceptedFiles[0]);
                    setSelectedImage(base64Image);
                } catch (error) {
                    console.error("Error converting file to Base64", error);
                }
            }
        }
    });

    const acceptedFileItems = acceptedFiles.map(file => file.path);

    const handleCloseDialog = () => {
        cleanForms();
        handleClose();
    };

    const cleanForms = () => {
        setSelectedDentists([])
        setSelectedPatientRecordPage('')
        setSelectedImage('')
        setObservation('')
        setDentistError('')
        setPatientRecordPageError('')
        setImageError('')
    }

    useEffect(() => {
        if (open && !initialized) {
            fetchDentistsDropdownOptions();
            setInitialized(true);
        }

    }, [open]);

    const fetchDentistsDropdownOptions = async () => {
        try {
            const response = await getUsers(user.access_token, 0, 10000, 'DENTIST');
            const options = response.data.content.map((user) => ({
                value: user.user_external_id,
                label: `${user.first_name} ${user.last_name}`,
            }));
            setDentistsDropdownOptions(options);
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const handleClosePatientRecordDialog = () => {
        cleanForms();
        handleClose();
    };

    const handleCreateConfirmationDialog = async () => {
        if (invalidForm())
            return;

        try {
            const response = await createPatientRecord(user.access_token,
                {
                    "patient_external_id": patientId,
                    "patient_record_page_number": selectedPatientRecordPage,
                    "patient_record_image": selectedImage,
                    "dentist_user_external_id_list": selectedDentists.map(dentist => dentist.value),
                    "observation": observation
                }
            );
            showSuccessToast(`Ficha do paciente criada com sucesso.`, 4000);
            handleClosePatientRecordDialog();
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
    };

    const invalidForm = () => {
        var formError = false;

        if (selectedDentists.length === 0) {
            setDentistError('Médicos dentista são obrigatório');
            formError = true;
        } else {
            setDentistError('');
        }

        if (!selectedPatientRecordPage) {
            setPatientRecordPageError('N.º página é obrigatório');
            formError = true;
        } else {
            setPatientRecordPageError('');
        }

        if (acceptedFileItems.length === 0) {
            setImageError('Imagem é obrigatória');
            formError = true;
        } else {
            setImageError('');
        }

        return formError;
    }

    return (
        <Dialog
            open={open}
            style={{ width: '100%' }}
            maxWidth={false}
            PaperProps={{
                style: {
                    width: '650px',
                    margin: 'auto',
                },
            }}
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleCreateConfirmationDialog}
            >
                {() => (
                    <Form id="patientForm" className="default-form">
                        <Typography variant="body1" style={{ marginBottom: 8, color: 'green' }}>
                            <IconButton color="inherit" aria-label="menu">
                                <AssignmentIcon />
                            </IconButton>
                            <strong>Upload de nova página - ficha do paciente</strong>
                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={9.5}>
                                <Autocomplete
                                    onChange={(event, query) => setSelectedDentists(query)}
                                    multiple
                                    id="tags-outlined"
                                    options={dentistsDropdownOptions}
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Médicos dentista vinculados"
                                            placeholder="Médico dentista"
                                        />
                                    )}
                                />
                                {dentistError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{dentistError}</div>}
                            </Grid>

                            <Grid item xs={12} sm={2.5}>
                                <Field
                                    type="text"
                                    name="patientRecordPage"
                                    value={selectedPatientRecordPage}
                                    label="N.º página"
                                    placeholder="1"
                                    as={TextField}
                                    variant="outlined"
                                    onChange={(event) => setSelectedPatientRecordPage(event.target.value)} // Sync with local state
                                />
                                {patientRecordPageError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{patientRecordPageError}</div>}
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <div {...getRootProps()} style={{
                                    border: '2px dashed #ccc',
                                    borderRadius: '4px',
                                    padding: '20px',
                                    margin: '20px 0',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: '#fafafa'
                                }}>
                                    <input {...getInputProps()} />
                                    <Typography variant="body1" color="textSecondary">
                                        Arraste e solte arquivos aqui ou clique para selecionar
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Somente arquivos em formato JPEG, JPG e PNG são aceitos.
                                    </Typography>
                                </div>

                                <aside style={{ paddingBottom: '10px' }}>
                                    <strong>Imagem selecionada:</strong> {acceptedFileItems.length > 0 ? acceptedFileItems : 'Não selecionado'}
                                    {imageError && <div style={{ color: 'red', fontSize: '0.75rem' }}>{imageError}</div>}
                                </aside>

                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Textarea
                                    name="observation"
                                    style={{ width: '100%' }}
                                    slots={{ textarea: InnerTextarea }}
                                    slotProps={{ textarea: { placeholder: 'Tratamento de cáries e restauração de dentes danificados.' } }}
                                    sx={{ borderRadius: '6px' }}
                                    onChange={(event) => setObservation(event.target.value)}
                                    value={observation}
                                />
                            </Grid>
                        </Grid>

                        <DialogActions sx={{ justifyContent: 'flex-start' }}>
                            <Button onClick={handleCloseDialog}
                                variant="contained"
                                style={{ backgroundColor: 'white', color: 'black' }}
                            >
                                Cancelar
                            </Button>
                            <div style={{ marginLeft: '20px' }}></div>
                            <Button type="submit"
                                variant="contained"
                                style={{ backgroundColor: 'darkgreen', color: 'white' }}
                            >
                                Salvar
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog >
    );

};

export default CreatePatientRecordDialog;
