const updateUserDetails = {
    personal_details: {
        first_name: 'string',
        last_name: 'string',
        contacts: [
            {
                contact_type: 'PHONE_NUMBER',
                contact_value: 'string',
            }
        ]
    },
    addresses: [
        {
            street: 'string',
            complement: 'string',
            district: 'string',
            city: 'string',
            zip_code: 'string'
        }
    ]
};

export const mapFormValuesToUpdateUserDetails = (formValues) => {
    return {
        personal_details: {
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            contacts: [
                {
                    contact_type: 'PHONE_NUMBER',
                    contact_value: formValues.phoneNumber,
                },
                {
                    contact_type: 'EMAIL',
                    contact_value: formValues.email,
                }                
            ]
        },
        addresses: [
            {
                street: formValues.street,
                complement: formValues.complement,
                district: formValues.locale,
                city: formValues.city,
                zip_code: formValues.zipCode,
            }
        ]
    };
};

export default updateUserDetails;