export const mapErrorCodeToMessage = (apiResponse) => {

    var defaultError = 'Um erro inesperado aconteceu, por favor contate a equipe de suporte e tecnologia.';

    if (apiResponse.response && apiResponse.response.data && apiResponse.response.data.internal_code) {
        switch (apiResponse.response.data.internal_code) {

            // CROSS ERRORS      
            case 'DENTAL_CROSS_002':
                return 'Utilizador não possui acesso a esse recurso.';
            case 'DENTAL_CROSS_004':
                return 'Falha no Login. Por favor verifique suas credenciais e tente novamente.';

            // GATEWAY ERRORS            
            case 'DENTAL_GATEWAY_002':
                return 'Utilizador não possui acesso a esse recurso.';
            case 'DENTAL_GATEWAY_003':
                return 'Sua sessão expirou, por favor realize log-in novamente.';
            case 'DENTAL_GATEWAY_004':
                return 'Sistema indisponivel no momento. Se o problema persistir por favor contate a equipe de suporte e tecnologia.'

            // USER-SERVICE ERRORS                
            case 'DENTAL_USER_003':
                return 'Cadastro do utilizador esta inativo, por favor contate o Administrador.';
            case 'DENTAL_USER_001':
                return 'Ja existe um utilizador com o username informado, corrija e tente novamente.';
            case 'DENTAL_USER_002':
                return 'Ja existe um utilizador com o email ou telemóvel informado, corrija e tente novamente.';
            case 'DENTAL_USER_004':
                return 'Não é possivel excluir seu próprio utilizador, por favor selecione outro.';
            case 'DENTAL_USER_005':
                return 'A senha atual esta incorreta, por favor revise e tente novamente.';
            case 'DENTAL_USER_006':
                return 'Link inválido ou expirado. Por favor, verifique e tente novamente.';

            // PATIENT-SERVICE ERRORS
            case 'DENTAL_PATIENT_001':
                return 'Ja há paciente registrado com: Cartão cidadão, Contribuinte (NIF) ou Numero utente. Por favor revise os dados e tente novamente.';
            case 'DENTAL_PATIENT_002':
                return 'O ID ja foi utilizado anteriormente para registrar algum paciente, mesmo que o paciente tenha sido removido, por favor utilize um novo número.'
            case 'DENTAL_PATIENT_003':
                return 'Arquivo selecionado não é suportado pela plataforma, por favor selecione um dos seguintes formatos: JPEG, JPG e PNG'
            case 'DENTAL_PATIENT_004':
                return 'O número da página já esta registrado no sistema, por favor revise o dado e tente novamente.'
            case 'DENTAL_PATIENT_005':
                return 'Não é possivel remover essa Convenção/Seguro, pois esta em uso por outros pacientes.'

            // SCHEDULE-SERVICE ERRORS
            case 'DENTAL_SCHEDULE_001':
                return 'Denstista selecionado com status inativo, por favor ative esse utilizador e tente novamente.';
            default:
                return defaultError;
        }
    } else {
        return defaultError;
    }

};


