import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/user-service/api/v1/auth`

const api = axios.create({
  baseURL: API_BASE_URL
});

export const login = async (credentials) => {
  try {
    const response = await api.post('/sign-in', credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default api;
