import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import { handleError } from '../../../helpers/ErrorHandling';
import { useState } from 'react';
import { useUser } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getAppointments } from '../../../services/ScheduleService';
import { mapAppointmentStatusToText } from '../../../helpers/EnumHelper';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

const getStatusIcon = (status) => {
    switch (status) {
        case 'CONFIRMED':
            return <CheckIcon style={{ color: 'green', minWidth: 'auto', marginRight: '2px', fontSize: '20px' }} />;
        case 'NOT_CONFIRMED':
            return <WarningIcon style={{ color: 'yellow', minWidth: 'auto', marginRight: '2px', fontSize: '20px' }} />;
        case 'CANCELED_RESCHEDULED':
            return <EventRepeatIcon style={{ color: 'orange', minWidth: 'auto', marginRight: '2px', fontSize: '16px' }} />;               
        case 'CANCELED_NOT_RESCHEDULED':
            return <CancelIcon style={{ color: 'red', minWidth: 'auto', marginRight: '2px', fontSize: '20px' }} />;           
        default:
            return null;
    }
};


const MedicalAppointmentsMainPage = ({ patient }) => {

    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const { user, clearUser } = useUser();
    const [loading, setLoading] = useState(true);
    const token = user?.access_token;

    const fetchData = async () => {
        try {
            const response = await getAppointments(token, null, null, patient.id, 'REDUCED');
            const userList = response.data || [];
            setRows(userList);
        } catch (error) {
            handleError(error, clearUser, navigate);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formatDateTime = (dateTimeString) => {
        const [date, time] = dateTimeString.split(' ');
        const [year, month, day] = date.split('/');
        return `${day}/${month}/${year} ${time}`;
    };

    return (
        <div>
            {loading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '60vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 750 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <Tooltip title="Data e hora da consutla agendada - Ordenado pela data mais recente">
                                            <TableCell align="center"><strong>Data / Hora</strong></TableCell>
                                        </Tooltip>
                                        <TableCell align="center"><strong>Médico dentista</strong></TableCell>
                                        <Tooltip title="Status do agendamento da consulta, indicado na aba 'Calendário'">
                                            <TableCell align="center"><strong>Status consulta</strong></TableCell>
                                        </Tooltip>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">Ainda não há consultas registradas para o paciente.</TableCell>
                                        </TableRow>
                                    ) : (
                                        rows.map((row) => (
                                            <TableRow key={row.appointment_external_id} sx={{ '& td': { padding: '8px' } }}>
                                                <TableCell align="center">{formatDateTime(row.start_event_date_time)}</TableCell>
                                                <TableCell align="center">{row.dentist_summary.dentist_name}</TableCell>
                                                <TableCell align="center">
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {getStatusIcon(row.appointment_status) && (
                                                            <span style={{ marginRight: '8px' }}>{getStatusIcon(row.appointment_status)}</span>
                                                        )}
                                                        <span style={{ fontSize: '14px' }}>{mapAppointmentStatusToText(row.appointment_status)}</span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default MedicalAppointmentsMainPage;