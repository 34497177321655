import axios from 'axios';

const API_BASE_URL_V2 = `${process.env.REACT_APP_API_BASE_URL}/patient-service/api/v2`

const apiv2 = axios.create({
    baseURL: API_BASE_URL_V2
});

export const getPatientRecordList = async (token, patientId) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await apiv2.get(`/patient-record/patient/${patientId}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updatePatientRecord = async (token, patientRecordId, patientRecordRegistration) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await apiv2.put(`/patient-record/${patientRecordId}`, patientRecordRegistration, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createPatientRecord = async (token, patientRecordRegistration) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await apiv2.post(`/patient-record`, patientRecordRegistration, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deletePatientRecord = async (token, patientRecordId) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await apiv2.delete(`/patient-record/${patientRecordId}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getPatientRecordDetails = async (token, patientRecordId) => {
    try {
        const authorizationHeader = `Bearer ${token}`;

        const response = await apiv2.get(`/patient-record/${patientRecordId}`, {
            headers: {
                Authorization: authorizationHeader,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};