import DentalDashboard from "../dashboard/DentalDashboard";
import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, TextField, Grid, Button, CircularProgress, Container } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { updatePasswordValidationSchema, updateProfileSchema } from '../../helpers/ValidationSchemaHelper.js';
import '../../styles/styles.css';
import MaskedTextField from '../../helpers/MaskHelper.js';
import FormError from '../../helpers/FormError.js';
import { useNavigate } from "react-router-dom";
import { getUserDetails, updateUserDetails, updateUserPassword } from '../../services/UserService.js';
import { useUser } from '../../contexts/UserContext';
import { showSuccessToast } from "../../helpers/ToastHelper.js";
import { mapUserTypeToText } from "../../helpers/EnumHelper.js";
import { mapFormValuesToUpdateUserDetails } from "../../data/updateUserDetails.js";
import { mapFormValuesToUpdatePassword } from "../../data/updateUserPassword.js";
import { HOMEPAGE_PATH, DASHBOARD_PATH } from "../../Constants.js";
import { handleError } from "../../helpers/ErrorHandling.js";

const SettingsProfile = () => {
    const { user, clearUser } = useUser();
    const token = user.access_token;
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        getUserDetails(token, user.user_external_id)
            .then((userDetails) => {
                setUserData(userDetails.data);
                setIsDataLoaded(true)
            })
            .catch((error) => {
                handleError(error, clearUser, navigate);
            });
    }, []);

    if (!userData) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '60vh' }}>
                <CircularProgress />
            </div>
        );
    }

    const validationSchema = isDataLoaded ? updateProfileSchema : null;

    const handleUpdate = async (values, { setSubmitting }) => {

        try {
            await updateProfileSchema.validate(values, { abortEarly: false });
            const mappedUserRegistration = mapFormValuesToUpdateUserDetails(values);
            await updateUserDetails(token, user.user_external_id, mappedUserRegistration);
            showSuccessToast('Perfil atualizado com sucesso', 1500)
        } catch (error) {
            handleError(error, clearUser, navigate);
        }
        setSubmitting(false);
    };

    const initialValues = {
        username: userData?.username || '',
        firstName: userData?.first_name || '',
        lastName: userData?.last_name || '',
        role: mapUserTypeToText(userData?.user_type_name) || '',
        email: userData?.contacts.find((contact) => contact.contact_type === 'EMAIL')?.value || '',
        phoneNumber: userData?.contacts.find((contact) => contact.contact_type === 'PHONE_NUMBER')?.value || '',
        street: userData?.addresses[0]?.street || '',
        complement: userData?.addresses[0]?.complement || '',
        locale: userData?.addresses[0]?.district || '',
        city: userData?.addresses[0]?.city || '',
        zipCode: userData?.addresses[0]?.zip_code || '',
    };

    const handleUpdatePassword = async (values, { setSubmitting }) => {
        try {
            await updatePasswordValidationSchema.validate(values, { abortEarly: false });
            const mappedUserPassword = mapFormValuesToUpdatePassword(values);

            await updateUserPassword(token, user.user_external_id, mappedUserPassword);

            showSuccessToast('Senha atualizada com sucesso, por favor realize o login novamente', 4000)
            clearUser();
            navigate(HOMEPAGE_PATH)

        } catch (error) {
            handleError(error, clearUser, navigate);
        }
        setSubmitting(false);
    };

    return (
        <DentalDashboard>
            <Container maxWidth="lg" sx={{ marginTop: 4 }}>
                <div style={{ maxWidth: '800px', width: '100%' }}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Perfil do utilizador
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>Atualize suas informações pessoais</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                                onSubmit={handleUpdate}
                            >
                                {() => (
                                    <Form className="default-form">
                                        <Typography variant="signup-data" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                                            Dados para seu Login:
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="text"
                                                    name="username"
                                                    label="Utilizador"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <br />
                                        <Typography variant="personal-data" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                                            Dados pessoais:
                                        </Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="text"
                                                    name="firstName"
                                                    label="Primeiro nome"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="firstName" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="text"
                                                    name="lastName"
                                                    label="Último nome"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="lastName" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="text"
                                                    name="role"
                                                    label="Perfil de utilizador"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly: true }}
                                                />
                                                <FormError name="role" />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="text"
                                                    name="email"
                                                    label="E-mail"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="email" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="text"
                                                    name="phoneNumber"
                                                    label="Telemóvel"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="phoneNumber" />
                                            </Grid>
                                        </Grid>

                                        <br />
                                        <Typography variant="address" gutterBottom align='left' style={{ fontWeight: 'bold', color: 'green' }}>
                                            Informações da morada:
                                        </Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={7}>
                                                <Field
                                                    type="text"
                                                    name="street"
                                                    label="Morada"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="street" />
                                            </Grid>

                                            <Grid item xs={12} sm={5}>
                                                <Field
                                                    type="text"
                                                    name="complement"
                                                    label="Complemento"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="complement" />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="text"
                                                    name="locale"
                                                    label="Localidade"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="locale" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="text"
                                                    name="city"
                                                    label="Cidade"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="city" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="text"
                                                    name="zipCode"
                                                    label="Código Postal"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="zipCode" />
                                            </Grid>
                                        </Grid>

                                        <div style={{ marginLeft: '20px' }}></div>
                                        <br />
                                        <Grid container spacing={2}>
                                            <Grid item xs={10} sm={2}>
                                                <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: 'white', color: 'black' }}
                                                    center=""
                                                    onClick={() => navigate(DASHBOARD_PATH)}
                                                >
                                                    Cancelar
                                                </Button>
                                            </Grid>

                                            <div style={{ marginLeft: '20px' }}></div>

                                            <Grid item xs={10} sm={4}>
                                                <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: 'darkgreen', color: 'white' }}
                                                    type="submit"
                                                >
                                                    Salvar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Senha de acesso
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>Atualize sua senha</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Formik
                                initialValues={{ oldPassword: '', newPassword: '', confirmNewPassword: '' }}
                                validationSchema={updatePasswordValidationSchema}
                                onSubmit={handleUpdatePassword}
                            >
                                {() => (
                                    <Form className="default-form">

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="password"
                                                    name="oldPassword"
                                                    label="Senha atual"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="oldPassword" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="password"
                                                    name="newPassword"
                                                    label="Nova senha"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="newPassword" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field
                                                    type="password"
                                                    name="confirmNewPassword"
                                                    label="Confirmação nova senha"
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormError name="confirmNewPassword" />
                                            </Grid>
                                        </Grid>

                                        <div style={{ marginLeft: '20px' }}></div>
                                        <br />
                                        <Grid container spacing={2}>
                                            <Grid item xs={10} sm={2}>
                                                <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: 'white', color: 'black' }}
                                                    center=""
                                                    onClick={() => navigate(HOMEPAGE_PATH)}
                                                >
                                                    Cancelar
                                                </Button>
                                            </Grid>

                                            <div style={{ marginLeft: '20px' }}></div>

                                            <Grid item xs={10} sm={4}>
                                                <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: 'darkgreen', color: 'white' }}
                                                    type="submit"
                                                >
                                                    Salvar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Container>
        </DentalDashboard>
    );
}

export default SettingsProfile;
